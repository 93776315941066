export const Color = {
  Accent: '#d50000',
  Background: '#fafafa',
  BackgroundAlternate: '#d50000',
  Overlay: 'rgba(0, 0, 0, 0.4)',
  TextPrimary: '#000',
  TextPrimaryAlternate: '#fafafa'
};

export const Gender = {
  Female: 'female',
  Male: 'male'
};

export const GENDERS = [Gender.Male, Gender.Female];

export const GuideItem = {
  MenuButton: 'menu_button'
};

export const RX_EMAIL = /^[^@]+@[^@]+$/;

export const SYMBOL_IMG_MAP = {
  breast: require('../assets/img/symbols/breast.png'),
  butt: require('../assets/img/symbols/butt.png'),
  drops: require('../assets/img/symbols/drops.png'),
  foot: require('../assets/img/symbols/foot.png'),
  hand: require('../assets/img/symbols/hand.png'),
  lips: require('../assets/img/symbols/lips.png'),
  penis: require('../assets/img/symbols/penis.png'),
  pussy: require('../assets/img/symbols/pussy.png'),
  toys: require('../assets/img/symbols/toys.png')
};
