import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import {findElement} from '../../../lib/helper';

import styles from './styles.less';

export const MenuPosition = {
  TopLeft: 'top_left',
  TopRight: 'top_right',
  BottomRight: 'bottom_right',
  BottomLeft: 'bottom_left'
};

export const MenuSeparator = 'separator';

const ITEM_TYPE = PropTypes.oneOfType([
  PropTypes.oneOf([MenuSeparator]),
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func
  })
]);

const Item = ({item, onCloseRequested}) => {
  if (item === MenuSeparator) {
    return (
      <div className={styles['separator-wrapper']}>
        <div className={styles.separator}/>
      </div>
    );
  }

  const {text, title, active, onClick} = item;

  const itemClassNames = classNames({
    [styles.item]: true,
    [styles['item-active']]: active
  });

  const handleClick = () => {
    onCloseRequested();

    onClick();
  };

  return (
    <div className={itemClassNames} title={title} onClick={handleClick}>
      <div className={styles['item-text']}>{text}</div>
    </div>
  );
};

Item.propTypes = {
  item: ITEM_TYPE.isRequired,
  onCloseRequested: PropTypes.func.isRequired
};

const renderItem = onCloseRequested => (item, index) => {
  const key = (item === MenuSeparator) ? `separator-${index}` : `item-${index}-${item.text}`;

  return (
    <Item key={key} item={item} onCloseRequested={onCloseRequested}/>
  );
};

const Menu = ({children, isVisible, items, minMenuWidth, position, userClassName, userStyle, onCloseRequested}) => {
  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (findElement(e.target, componentRef.current)) {
        return;
      }

      onCloseRequested();
    };

    window.document.addEventListener('click', handleClickOutside);

    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const menuClassNames = classNames({
    [styles.menu]: true,
    [styles['menu-position-top-left']]: position === MenuPosition.TopLeft,
    [styles['menu-position-top-right']]: position === MenuPosition.TopRight,
    [styles['menu-position-bottom-right']]: position === MenuPosition.BottomRight,
    [styles['menu-position-bottom-left']]: position === MenuPosition.BottomLeft
  });

  const menuStyle = {minWidth: `${minMenuWidth}px`};

  if (!isVisible) {
    menuStyle.display = 'none';
  }

  return (
    <div ref={componentRef} className={classNames(styles.component, userClassName)} style={userStyle}>
      {children}
      <div className={menuClassNames} style={menuStyle}>
        {items.map(renderItem(onCloseRequested))}
      </div>
    </div>
  );
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  items: PropTypes.arrayOf(ITEM_TYPE).isRequired,
  minMenuWidth: PropTypes.number,
  position: PropTypes.oneOf(Object.values(MenuPosition)),
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onCloseRequested: PropTypes.func.isRequired
};

Menu.defaultProps = {
  isVisible: false,
  minMenuWidth: 140,
  position: MenuPosition.TopLeft,
  userClassName: null,
  userStyle: null
};

export default Menu;
