import React from 'react';
import {useSelector} from 'react-redux';

import {SYMBOL_IMG_MAP} from '../../../../lib/constants';
import {GameStage, SLOT_COUNT} from '../../../../lib/game';

import useRandomSymbols from '../../../../hooks/use-random-symbols';
import useSlotDimensions from '../../../../hooks/use-slot-dimensions';

import Image from '../../../common/image';

import styles from './styles.less';

const {symbols} = require('../../../../assets/data/symbols');

const IMG_QUESTION = require('../../../../assets/img/question.png');

function getImageData({randomSymbols, slotIndex, stage, task}) {
  switch (stage) {
  case GameStage.Spinning: {
    const symbol = randomSymbols[slotIndex];

    return symbol ? SYMBOL_IMG_MAP[symbol.name] : IMG_QUESTION;
  }

  case GameStage.Performing: {
    if (!task) {
      return IMG_QUESTION;
    }

    const symbol = symbols.find(item => item.name === task.symbols[slotIndex]);

    return symbol ? SYMBOL_IMG_MAP[symbol.name] : IMG_QUESTION;
  }

  case GameStage.Initial:
  default:
    return IMG_QUESTION;
  }
}

const Slots = () => {
  const {stage, task} = useSelector(state => state.game);

  const randomSymbols = useRandomSymbols(stage === GameStage.Spinning);
  const {width, height} = useSlotDimensions();

  return (
    <div className={styles['slots-container']}>
      <div className={styles.slots}>
        {
          /* eslint-disable react/no-array-index-key */
          Array(SLOT_COUNT).fill(null).map((_, slotIndex) => (
            <div key={`slot-${slotIndex}`} className={styles['slot-container']}>
              <div className={styles.slot} style={{height: `${height}px`}}>
                <Image data={getImageData({randomSymbols, slotIndex, stage, task})} extent={width}/>
              </div>
            </div>
          ))
          /* eslint-enable react/no-array-index-key */
        }
      </div>
    </div>
  );
};

export default Slots;
