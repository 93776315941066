import PropTypes from 'prop-types';
import React from 'react';
import {useField, useFormContext} from 'react-form';

import CheckboxFormField from './checkbox';
import InputFormField from './input';
import TextareaFormField from './textarea';

const FormField = ({render, ...fieldProps}) => {
  const {
    hasCopyButton,
    isDisabled,
    mask,
    maskChar,
    max,
    min,
    name,
    label,
    placeholder,
    rowCount,
    type,
    validate,
    onChange
  } = fieldProps;

  const fieldInstance = useField(name, {validate});

  const {
    meta: {
      error: formError
    },
    setMeta: setFormMeta
  } = useFormContext();

  const {
    fieldName,
    setMeta,
    value,
    setValue: setFieldValue,
    meta: {error, isTouched}
  } = fieldInstance;

  const setTouched = () => {
    setMeta({isTouched: true});

    if (formError) {
      setFormMeta({error: false});
    }
  };

  const setValue = value => setFieldValue(value, {isTouched: false});

  switch (type) {
  case 'checkbox':
    return (
      <CheckboxFormField
        error={error}
        isChecked={value}
        isDisabled={isDisabled}
        isTouched={isTouched}
        label={label}
        name={fieldName}
        value={value}
        setTouched={setTouched}
        setValue={setValue}
        onChange={onChange}
      />
    );
  case 'custom':
    return render({
      fieldProps,
      value,
      fieldApi: fieldInstance
    });
  case 'hidden':
    return (
      <input name={fieldName} type="hidden" value={value}/>
    );
  case 'text_multiline':
    return (
      <TextareaFormField
        error={error}
        isDisabled={isDisabled}
        isTouched={isTouched}
        label={label}
        name={fieldName}
        placeholder={placeholder}
        rowCount={rowCount}
        value={value}
        setTouched={setTouched}
        setValue={setValue}
        onChange={onChange}
      />
    );
  default:
    return (
      <InputFormField
        error={error}
        hasCopyButton={hasCopyButton}
        isDisabled={isDisabled}
        isTouched={isTouched}
        label={label}
        mask={mask}
        maskChar={maskChar}
        max={max}
        min={min}
        name={fieldName}
        placeholder={placeholder}
        type={type}
        value={value}
        setTouched={setTouched}
        setValue={setValue}
        onChange={onChange}
      />
    );
  }
};

FormField.TYPE = {
  EMAIL: 'email',
  CHECKBOX: 'checkbox',
  CUSTOM: 'custom',
  HIDDEN: 'hidden',
  NUMBER: 'number',
  TEXT: 'text',
  TEXT_MULTILINE: 'text_multiline',
  PASSWORD: 'password'
};

FormField.propTypes = {
  fieldCount: PropTypes.number,
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rowCount: PropTypes.number,
  type: PropTypes.oneOf(Object.values(FormField.TYPE)).isRequired,
  render: PropTypes.func,
  validate: PropTypes.func,
  onChange: PropTypes.func
};

FormField.defaultProps = {
  fieldCount: null,
  isDisabled: false,
  label: null,
  max: null,
  min: null,
  placeholder: null,
  rowCount: null,
  render: null,
  validate: null,
  onChange: null
};

export default FormField;
