import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.less';

const Button = props => {
  const {
    domRef,
    isDisabled,
    kind,
    text,
    type,
    userClassName,
    userStyle,
    width,
    onClick
  } = props;

  const buttonClassNames = classNames({
    [userClassName]: Boolean(userClassName),
    [styles.button]: true,
    [styles.default]: kind === Button.KIND.DEFAULT,
    [styles.primary]: kind === Button.KIND.PRIMARY,
    [styles['width-normal']]: width === Button.WIDTH.NORMAL,
    [styles['width-fill']]: width === Button.WIDTH.FILL,
    [styles.disabled]: isDisabled
  });

  const clickHandler = e => {
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  };

  /* eslint-disable react/button-has-type */
  return (
    <button
      ref={domRef}
      className={buttonClassNames}
      disabled={isDisabled}
      style={userStyle}
      type={type}
      onClick={isDisabled ? null : clickHandler}
    >
      {text.toUpperCase()}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

Button.HEIGHT = {
  NORMAL: 'normal',
  SMALL: 'small'
};

Button.KIND = {
  DEFAULT: 'default',
  PRIMARY: 'primary'
};

Button.TYPE = {
  BUTTON: 'button',
  RESET: 'reset',
  SUBMIT: 'submit'
};

Button.WIDTH = {
  NORMAL: 'normal',
  FILL: 'fill'
};

Button.propTypes = {
  domRef: PropTypes.func,
  isDisabled: PropTypes.bool,
  kind: PropTypes.oneOf(Object.values(Button.KIND)),
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Button.TYPE)),
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  width: PropTypes.oneOf(Object.values(Button.WIDTH)),
  onClick: PropTypes.func
};

Button.defaultProps = {
  domRef: null,
  isDisabled: false,
  kind: Button.KIND.DEFAULT,
  type: Button.TYPE.BUTTON,
  userClassName: null,
  userStyle: null,
  width: Button.WIDTH.NORMAL,
  onClick: null
};

export default Button;
