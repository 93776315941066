import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

const subnamespace = defineAction('last-task-ids');

const ADD_LAST_TASK_ID = defineAction('ADD_LAST_TASK_ID', subnamespace);

const addLastTaskId = createAction(ADD_LAST_TASK_ID.ACTION);

const INITIAL_STATE = {
  ids: []
};

function handleAddLastTaskId(state, {payload}) {
  const newIds = [...state.ids];

  const index = newIds.indexOf(payload);

  if (index) {
    newIds.splice(index, 1);
  }

  newIds.push(payload);

  return {
    ...state,
    ids: newIds
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    ADD_LAST_TASK_ID
  },
  actions: {
    addLastTaskId
  },
  reducer: createReducer(INITIAL_STATE, {
    [ADD_LAST_TASK_ID.ACTION]: handleAddLastTaskId
  })
};

export default DUCK;
