import {useEffect, useState} from 'react';

const useHover = ref => {
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    const node = ref.current;

    if (!node) {
      return;
    }

    const handleMouseOver = () => setHovered(true);

    const handleMouseOut = () => setHovered(false);

    node.addEventListener('mouseenter', handleMouseOver);
    node.addEventListener('mouseleave', handleMouseOut);

    return () => {
      node.removeEventListener('mouseenter', handleMouseOver);
      node.removeEventListener('mouseleave', handleMouseOut);
    };
  }, [ref]);

  return isHovered;
};

export default useHover;
