// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1orR_CmT{vertical-align:middle}", "",{"version":3,"sources":["webpack://src/components/common/image/styles.less"],"names":[],"mappings":"AAAA,WACE,qBACF","sourcesContent":[".image {\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "_1orR_CmT"
};
export default ___CSS_LOADER_EXPORT___;
