import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.less';

const CheckboxFormField = ({error, isChecked, isDisabled, isTouched, label, name, setTouched, setValue, onChange}) => {
  const hasError = Boolean(isTouched && error);

  const checkboxFormFieldClassNames = classNames({
    [styles['checkbox-form-field']]: true,
    [styles.checked]: isChecked
  });
  const checkboxClassNames = classNames({
    [styles.checkbox]: true,
    [styles['with-error']]: hasError
  });

  const doHandleChange = checked => {
    setValue(checked);
    setTouched();
  };

  const handleChange = e => {
    const {checked} = e.target;

    if (onChange) {
      onChange(checked, doHandleChange);
    } else {
      doHandleChange(checked);
    }
  };

  const checkbox = (
    <input
      checked={isChecked}
      className={checkboxClassNames}
      disabled={isDisabled}
      name={name}
      type="checkbox"
      onChange={handleChange}
    />
  );

  return (
    <div className={checkboxFormFieldClassNames}>
      {
        label ? (
          <label className={styles.label}>
            {checkbox}
            {(typeof label === 'string') ? ` ${label}` : label}
          </label>
        ) : checkbox
      }
      {
        isTouched && error && (
          <div className={styles.error}>{error}</div>
        )
      }
    </div>
  );
};

CheckboxFormField.propTypes = {
  error: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

CheckboxFormField.defaultProps = {
  error: null,
  isChecked: false,
  isDisabled: false,
  isTouched: false,
  label: null,
  onChange: null
};

export default CheckboxFormField;
