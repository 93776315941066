import {promisifiedDispatch} from '@ololoepepe/redux-api';

import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import Auth from '../../../../redux/ducks/auth';

import {
  getLogInEmailPasswordErrors,
  getPasswordResetEmailErrors,
  getRegisterEmailPasswordErrors
} from '../../../../lib/form/error-getters';
import {RESET_PASSWORD_FORM_FIELDS, SIGN_UP_FORM_FIELDS, createLogInFormFields} from '../../../../lib/form/fields';
import {handleSubmitFailure} from '../../../../lib/form/helper';
import {validatePasswordsMatch} from '../../../../lib/form/validators';

import ClickableText from '../../clickable-text';
import Form from '../../../common/form';

import styles from './styles.less';

export const AuthMode = {
  LogIn: 'log_in',
  ResetPassword: 'reset_password',
  SignUp: 'sign_up'
};

const AuthModalContent = ({mode, onCloseRequested, onModeChange}) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [hasReset, setHasReset] = useState(false);

  const actionAlignment = (mode === AuthMode.LogIn) ? Form.ACTION_ALIGNMENT.CENTER : Form.ACTION_ALIGNMENT.END;

  const actions = (mode === AuthMode.LogIn) ? [{
    text: 'Google',
    userClassName: styles['btn-google'],
    onClick: () => {
      promisifiedDispatch(dispatch, Auth.actions.signInGoogle).then(onCloseRequested).catch(err => console.warn(err));
    }
  }, {
    isPrimary: true,
    text: 'Отправить'
  }] : (
    ((mode === AuthMode.SignUp) || !hasReset) ? [{
      isPrimary: true,
      text: 'Отправить'
    }] : []
  );

  const defaultValues = (mode === AuthMode.ResetPassword) ? {email} : undefined;

  const fields = (mode === AuthMode.LogIn) ? createLogInFormFields({
    onEmailChange: setEmail
  }) : ((mode === AuthMode.SignUp) ? SIGN_UP_FORM_FIELDS : (hasReset ? [] : RESET_PASSWORD_FORM_FIELDS));

  const title = (mode === AuthMode.LogIn) ?
    'Вход' :
    ((mode === AuthMode.SignUp) ? 'Регистрация' : 'Восстановление пароля');

  const validate = (mode === AuthMode.SignUp) ? validatePasswordsMatch : undefined;

  const handleSubmit = async data => {
    switch (mode) {
    case AuthMode.LogIn:
      await promisifiedDispatch(dispatch, Auth.actions.logInEmailPassword, {data});

      onCloseRequested();

      break;
    case AuthMode.SignUp:
      await promisifiedDispatch(dispatch, Auth.actions.registerEmailPassword, {data});

      onCloseRequested();

      break;
    case AuthMode.ResetPassword:
      await promisifiedDispatch(dispatch, Auth.actions.sendPasswordResetEmail, {data});

      setHasReset(true);

      break;
    default:
      break;
    }
  };

  const getErrors = (mode === AuthMode.LogIn) ?
    getLogInEmailPasswordErrors :
    ((mode === AuthMode.SignUp) ? getRegisterEmailPasswordErrors : getPasswordResetEmailErrors);

  const handleModeSwitch = () => {
    onModeChange((mode === AuthMode.LogIn) ? AuthMode.SignUp : AuthMode.LogIn);
  };

  return (
    <div className={styles.component}>
      <Form
        key={mode}
        actionAlignment={actionAlignment}
        actions={actions}
        defaultValues={defaultValues}
        fields={fields}
        title={title}
        validate={validate}
        onSubmit={handleSubmit}
        onSubmitFailure={handleSubmitFailure(getErrors)}
      >
        {
          hasReset && (
            <div>
              <p>
                Мы отправили инструкцию по смене пароля на вашу электронную почту
              </p>
            </div>
          )
        }
      </Form>
      <div className={styles.footer}>
        {
          (mode === AuthMode.LogIn) ? (
            <ClickableText text="Забыли пароль?" onClick={() => onModeChange(AuthMode.ResetPassword)}/>
          ) : (
            <div/>
          )
        }
        <ClickableText text={(mode === AuthMode.LogIn) ? 'Регистрация' : 'Вход'} onClick={handleModeSwitch}/>
      </div>
    </div>
  );
};

AuthModalContent.propTypes = {
  mode: PropTypes.oneOf(Object.values(AuthMode)),
  onCloseRequested: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired
};

AuthModalContent.defaultProps = {
  mode: AuthMode.LogIn
};

export default AuthModalContent;
