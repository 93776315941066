import * as firebase from 'firebase/app';

import {createDuck} from '../../lib/firebase-provider';

export default createDuck('userProducts', {
  collectionName: 'userProducts',
  createQuery: collection => {
    return collection.where('userId', '==', firebase.auth().currentUser.uid);
  },
  mapData: ({createdAt, ...rest}) => ({
    ...rest,
    createdAt: createdAt.toDate()
  })
});
