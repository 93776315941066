// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tW91AZSq{margin-top:20px;-js-display:flex;display:flex;justify-content:flex-end}._1SR5vfYp{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);padding:20px;background-color:#fafafa;border-radius:3px;pointer-events:auto}@media (max-width:900px){._1SR5vfYp{top:60%;transform:translate(-50%,-40%);min-width:300px}}._25P_GPGv{position:absolute;border-radius:100%;box-shadow:0 0 0 2000px rgba(0,0,0,.6)}._25P_GPGv,._1KKuwPxe{background-color:initial;pointer-events:none}._1KKuwPxe{position:fixed;z-index:3000;top:0;left:0;height:100%;width:100%;overflow:hidden}.JRFKxRdX{white-space:pre-line}", "",{"version":3,"sources":["webpack://src/components/common/guide/styles.less"],"names":[],"mappings":"AAAA,UACE,eAAgB,CAChB,gBAAa,CAAb,YAAa,CACb,wBACF,CACA,WACE,iBAAkB,CAClB,QAAS,CACT,OAAQ,CACR,8BAAgC,CAChC,YAAa,CACb,wBAAyB,CACzB,iBAAkB,CAClB,mBACF,CACA,yBACE,WACE,OAAQ,CACR,8BAAgC,CAChC,eACF,CACF,CACA,WACE,iBAAkB,CAElB,kBAAmB,CACnB,sCAEF,CACA,sBALE,wBAA6B,CAG7B,mBAYF,CAVA,WACE,cAAe,CACf,YAAa,CACb,KAAM,CACN,MAAO,CACP,WAAY,CACZ,UAAW,CACX,eAGF,CACA,UACE,oBACF","sourcesContent":[".action-wrapper {\n  margin-top: 20px;\n  display: flex;\n  justify-content: flex-end;\n}\n.content {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  background-color: #fafafa;\n  border-radius: 3px;\n  pointer-events: auto;\n}\n@media (max-width: 900px) {\n  .content {\n    top: 60%;\n    transform: translate(-50%, -40%);\n    min-width: 300px;\n  }\n}\n.hollow {\n  position: absolute;\n  background-color: transparent;\n  border-radius: 100%;\n  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.6);\n  pointer-events: none;\n}\n.overlay {\n  position: fixed;\n  z-index: 3000;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  background-color: transparent;\n  pointer-events: none;\n}\n.text {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-wrapper": "tW91AZSq",
	"content": "_1SR5vfYp",
	"hollow": "_25P_GPGv",
	"overlay": "_1KKuwPxe",
	"text": "JRFKxRdX"
};
export default ___CSS_LOADER_EXPORT___;
