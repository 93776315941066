const SCREEN_WIDTH_MAX = 2000;
const SCREEN_WIDTH_MIN = 550;
const SCREEN_HEIGHT_MIN = 500;

export function delay(msecs) {
  return new Promise(resolve => {
    setTimeout(resolve, msecs);
  });
}

export function findElement(target, element) {
  let el = target;

  while (el) {
    if (el === element) {
      return true;
    }

    el = el.parentNode;
  }

  return false;
}

export function getRandomInteger(min, max) {
  return Math.floor(min + (Math.random() * (max + 1 - min)));
}

export function getWindowSize() {
  return {
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  };
}

export function isLandscapeLayout({width, height}) {
  if (width > SCREEN_WIDTH_MAX) {
    return true;
  }

  if (height > SCREEN_HEIGHT_MIN) {
    return false;
  }

  return (width > SCREEN_WIDTH_MIN);
}

export function stringifyQuery(query) {
  return Object.keys(query).map(key => {
    const value = query[key];

    if (!Array.isArray(value)) {
      return `${key}=${encodeURIComponent(value)}`;
    }

    return value.map(v => `${key}=${encodeURIComponent(v)}`).join('&');
  }).join('&');
}
