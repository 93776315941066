import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

import {GameStage} from '../../lib/game';

const subnamespace = defineAction('game');

const FINISH_GAME = defineAction('FINISH_GAME', subnamespace);
const SET_TASK = defineAction('SET_TASK', subnamespace);
const START_GAME = defineAction('START_GAME', subnamespace);

const finishGame = createAction(FINISH_GAME.ACTION);
const setTask = createAction(SET_TASK.ACTION);
const startGame = createAction(START_GAME.ACTION);

const INITIAL_STATE = {
  stage: GameStage.Initial,
  task: null
};

function handleFinishGame(state) {
  return {
    ...state,
    stage: GameStage.Initial,
    task: null
  };
}

function handleStartGame(state) {
  return {
    ...state,
    stage: GameStage.Spinning
  };
}

function handleSetTask(state, {payload}) {
  return {
    ...state,
    stage: GameStage.Performing,
    task: payload
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    FINISH_GAME,
    SET_TASK,
    START_GAME
  },
  actions: {
    finishGame,
    setTask,
    startGame
  },
  reducer: createReducer(INITIAL_STATE, {
    [FINISH_GAME.ACTION]: handleFinishGame,
    [SET_TASK.ACTION]: handleSetTask,
    [START_GAME.ACTION]: handleStartGame
  })
};

export default DUCK;
