// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Nuocj3p{-js-display:flex;display:flex;flex-direction:column;align-items:center;flex-grow:1;width:100%;padding:10px 20px 20px;box-sizing:border-box}._2Nuocj3p.f8HOV8dQ{justify-content:center}._3uKQKSg5{text-align:center}._17QKmGJX{width:100%;max-width:500px;padding-right:20px;box-sizing:border-box;font-size:16px}._17QKmGJX+._17QKmGJX{margin-top:10px}._18d4cPQJ{height:24px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://src/components/pages/blacklist/styles.less"],"names":[],"mappings":"AAAA,WACE,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,WAAY,CACZ,UAAW,CACX,sBAA4B,CAC5B,qBACF,CACA,oBACE,sBACF,CACA,WACE,iBACF,CACA,WACE,UAAW,CACX,eAAgB,CAChB,kBAAmB,CACnB,qBAAsB,CACtB,cACF,CACA,sBACE,eACF,CACA,WACE,WAAY,CACZ,eAAgB,CAChB,kBAAmB,CACnB,sBACF","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex-grow: 1;\n  width: 100%;\n  padding: 10px 20px 20px 20px;\n  box-sizing: border-box;\n}\n.content.content-empty {\n  justify-content: center;\n}\n.dummy {\n  text-align: center;\n}\n.item {\n  width: 100%;\n  max-width: 500px;\n  padding-right: 20px;\n  box-sizing: border-box;\n  font-size: 16px;\n}\n.item + .item {\n  margin-top: 10px;\n}\n.label {\n  height: 24px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_2Nuocj3p",
	"content-empty": "f8HOV8dQ",
	"dummy": "_3uKQKSg5",
	"item": "_17QKmGJX",
	"label": "_18d4cPQJ"
};
export default ___CSS_LOADER_EXPORT___;
