// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tc7ASfId{padding-left:5px;font-size:12px}._3XU4tz7P{flex-grow:1;padding:10px 0 20px}._3pJCBtS2{font-size:16px}._3pJCBtS2+._3pJCBtS2{margin-top:10px}._3t5A1K9P{font-size:16px}._1xWzArea{margin-top:30px;-js-display:flex;display:flex;justify-content:flex-end}._3MH1-9XY{width:300px;padding:20px;box-sizing:border-box}._3xviSe3l{margin-top:15px}._3dnKxFvm{-js-display:flex;display:flex;justify-content:center;margin:15px 0;font-size:20px;font-weight:700}.cSx4WtVM{margin-top:25px}", "",{"version":3,"sources":["webpack://src/components/pages/settings/styles.less"],"names":[],"mappings":"AAAA,UACE,gBAAiB,CACjB,cACF,CACA,WACE,WAAY,CACZ,mBACF,CACA,WACE,cACF,CACA,sBACE,eACF,CACA,WACE,cACF,CACA,WACE,eAAgB,CAChB,gBAAa,CAAb,YAAa,CACb,wBACF,CACA,WACE,WAAY,CACZ,YAAa,CACb,qBACF,CACA,WACE,eACF,CACA,WACE,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,aAAc,CACd,cAAe,CACf,eACF,CACA,UACE,eACF","sourcesContent":[".badge {\n  padding-left: 5px;\n  font-size: 12px;\n}\n.content {\n  flex-grow: 1;\n  padding: 10px 0 20px 0;\n}\n.item {\n  font-size: 16px;\n}\n.item + .item {\n  margin-top: 10px;\n}\n.label {\n  font-size: 16px;\n}\n.product-modal-button-wrapper {\n  margin-top: 30px;\n  display: flex;\n  justify-content: flex-end;\n}\n.product-modal-content {\n  width: 300px;\n  padding: 20px;\n  box-sizing: border-box;\n}\n.section-body {\n  margin-top: 15px;\n}\n.section-title {\n  display: flex;\n  justify-content: center;\n  margin: 15px 0;\n  font-size: 20px;\n  font-weight: 700;\n}\n.settings {\n  margin-top: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "Tc7ASfId",
	"content": "_3XU4tz7P",
	"item": "_3pJCBtS2",
	"label": "_3t5A1K9P",
	"product-modal-button-wrapper": "_1xWzArea",
	"product-modal-content": "_3MH1-9XY",
	"section-body": "_3xviSe3l",
	"section-title": "_3dnKxFvm",
	"settings": "cSx4WtVM"
};
export default ___CSS_LOADER_EXPORT___;
