const SERVER_SIDE_ERROR = 'Проблемы на стороне сервера. Попробуйте повторить позже.';
const UNEXPECTED_ERROR =
  'Непредвиденная ошибка. Попробуйте повторить позже, или свяжитесь с разработчиками.';

export function getLogInEmailPasswordErrors(err) {
  console.warn(err);

  if (['auth/wrong-password', 'auth/user-not-found'].includes(err.code)) {
    return {_error: 'Неверное имя пользователя или пароль'};
  }

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getRegisterEmailPasswordErrors(err) {
  console.warn(err);

  if (['auth/email-already-in-use'].includes(err.code)) {
    return {_error: 'Такой пользователь уже есть в системе'};
  }

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getPasswordResetEmailErrors(err) {
  console.warn(err);

  if (['auth/user-not-found'].includes(err.code)) {
    return {_error: 'Такого пользователя нет в системе'};
  }

  const {response} = err;

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  return {_error: SERVER_SIDE_ERROR};
}
