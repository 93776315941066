import * as firebase from 'firebase/app';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import useHover from '../../../../hooks/use-hover';

import TaskRatings from '../../../../redux/ducks/task-ratings';

import Star from './star';

import styles from './styles.less';
import {createTaskId} from '../../../../lib/game';

const Rating = ({task}) => {
  const dispatch = useDispatch();

  const {
    isCreating,
    items: taskRatings
  } = useSelector(state => state.taskRatings);

  const starsContainerRef = useRef(null);

  const isHovered = useHover(starsContainerRef);

  const [currentRating, setCurrentRating] = useState(0);

  const symbolsString = task.symbols.join('-');

  const taskRating = taskRatings.find(({index, symbols}) => {
    return (symbols.join('-') === symbolsString) && (index === task.index);
  });

  const handleStarClick = rating => () => {
    const userId = firebase.auth().currentUser.uid;

    dispatch(TaskRatings.actions.create({
      data: {
        rating,
        userId,
        createdAt: new Date(),
        index: task.index,
        symbols: task.symbols
      },
      id: `${createTaskId(task)}-${userId}`
    }));
  };

  const handleStarHover = rating => () => {
    setCurrentRating(rating);
  };

  return (
    <div className={styles.component}>
      <div>Оцените задание:</div>
      <div ref={starsContainerRef} className={styles['stars-container']}>
        {
          Array(5).fill(null).map((_, starIndex) => {
            const rating = starIndex + 1;

            const isActive = (isHovered || isCreating) ?
              (currentRating >= rating) :
              (Boolean(taskRating) && (taskRating.rating >= rating));

            return (
              <div key={rating} className={styles['star-wrapper']}>
                <Star
                  isActive={isActive}
                  isDisabled={isCreating}
                  onClick={handleStarClick(rating)}
                  onHover={handleStarHover(rating)}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

Rating.propTypes = {
  task: PropTypes.object.isRequired
};

export default Rating;
