// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2F9ZdVUD{white-space:pre-wrap;line-height:1.5;padding:20px}._2afILEag,.mU-wyH3q{color:#b71c1c}.mU-wyH3q{font-size:20px;font-weight:700}.htbQNLQB{list-style-type:none}", "",{"version":3,"sources":["webpack://src/components/pages/about/styles.less"],"names":[],"mappings":"AAAA,WACE,oBAAqB,CACrB,eAAgB,CAChB,YACF,CAIA,qBAFE,aAMF,CAJA,UACE,cAAe,CACf,eAEF,CACA,UACE,oBACF","sourcesContent":[".content {\n  white-space: pre-wrap;\n  line-height: 1.5;\n  padding: 20px;\n}\n.emoji {\n  color: #b71c1c;\n}\n.header {\n  font-size: 20px;\n  font-weight: 700;\n  color: #b71c1c;\n}\n.list {\n  list-style-type: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_2F9ZdVUD",
	"emoji": "_2afILEag",
	"header": "mU-wyH3q",
	"list": "htbQNLQB"
};
export default ___CSS_LOADER_EXPORT___;
