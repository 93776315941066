import {combineReducers} from 'redux';

import Auth from '../ducks/auth';
import Game from '../ducks/game';
import Guide from '../ducks/guide';
import LastTaskIds from '../ducks/last-task-ids';
import Maturity from '../ducks/maturity';
import Session from '../ducks/session';
import Settings from '../ducks/settings';
import TaskRatings from '../ducks/task-ratings';
import Tasks from '../ducks/tasks';
import User from '../ducks/user';
import UserProducts from '../ducks/user-products';

export default combineReducers({
  auth: Auth.reducer,
  game: Game.reducer,
  guide: Guide.reducer,
  lastTaskIds: LastTaskIds.reducer,
  maturity: Maturity.reducer,
  session: Session.reducer,
  settings: Settings.reducer,
  taskRatings: TaskRatings.reducer,
  tasks: Tasks.reducer,
  user: User.reducer,
  userProducts: UserProducts.reducer
});
