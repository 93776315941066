// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1oeXAedA{height:40px;width:40px;color:#a9a9a9;cursor:pointer}._1oeXAedA._1QmXyTjZ{color:#d50000}._1oeXAedA._2JzST8dg{cursor:not-allowed}._3l0GAmfa{height:100%!important;width:100%!important}", "",{"version":3,"sources":["webpack://src/components/pages/game/rating/star/styles.less"],"names":[],"mappings":"AAAA,WACE,WAAY,CACZ,UAAW,CACX,aAAc,CACd,cACF,CACA,qBACE,aACF,CACA,qBACE,kBACF,CACA,WACE,qBAAuB,CACvB,oBACF","sourcesContent":[".component {\n  height: 40px;\n  width: 40px;\n  color: #a9a9a9;\n  cursor: pointer;\n}\n.component.component-active {\n  color: #d50000;\n}\n.component.component-disabled {\n  cursor: not-allowed;\n}\n.icon {\n  height: 100% !important;\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_1oeXAedA",
	"component-active": "_1QmXyTjZ",
	"component-disabled": "_2JzST8dg",
	"icon": "_3l0GAmfa"
};
export default ___CSS_LOADER_EXPORT___;
