import {useCallback, useState} from 'react';

const useMenu = ({isVisible = false} = {}) => {
  const [isMenuVisible, setMenuVisible] = useState(isVisible);

  const closeMenu = useCallback(() => setMenuVisible(false), []);

  const openMenu = useCallback(() => setMenuVisible(true), []);

  const toggleMenu = useCallback(() => setMenuVisible(!isMenuVisible), [isMenuVisible]);

  return [{
    isVisible: isMenuVisible,
    onCloseRequested: closeMenu
  }, {closeMenu, openMenu, toggleMenu}];
};

export default useMenu;
