import * as firebase from 'firebase/app';

import {stringifyQuery} from './helper';

const {products} = require('../assets/data/products.json');

const YANDEX_MONEY_WALLET = '410014080218122';

export function purchase({name, type}, authCallback = null) {
  const {currentUser} = firebase.auth();

  if (!currentUser) {
    // eslint-disable-next-line no-alert
    if (confirm('Пожалуйста, зарегистрируйтесь, чтобы совершить покупку') && authCallback) {
      authCallback();
    }

    return;
  }

  const {description, paymentMethods, redirectUrl} = products.find(p => (p.name === name) && (p.type === type));

  const {paymentType, sum} = paymentMethods[0].paymentSystems[0].data;

  const {protocol, host} = window.location;

  const q = {
    paymentType,
    sum,
    formcomment: description,
    label: `${currentUser.uid}-${type}.${name}`,
    'quickpay-form': 'shop',
    receiver: YANDEX_MONEY_WALLET,
    'short-dest': description,
    successURL: `${protocol}//${host}${redirectUrl}`,
    targets: description
  };

  window.location.href = `https://yoomoney.ru/quickpay/confirm.xml?${stringifyQuery(q)}`;
}
