import EmailIcon from 'mdi-material-ui/Email';
import React from 'react';

import styles from './styles.less';

const Footer = () => {
  return (
    <footer className={styles.component}>
      <div className={styles['left-container']}>
        <a className={styles['copyright-link']} href="https://void-walkers.com" rel="noreferrer" target="_blank">
          © 2017-2020 Void Walkers
        </a>
        <a className={styles['email-link']} href="mailto:info@slut-machine.net">
          <EmailIcon className={styles['email-icon']} fontSize="inherit"/>
          {' info@slut-machine.net'}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
