import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.less';

const TabBar = ({activeItemIndex, items, onActiveItemIndexChangeRequested}) => {
  return (
    <div>
      <div className={styles.component}>
        {
          items.map(({title}, index) => {
            const isActive = index === activeItemIndex;

            const itemClassNames = classNames({
              [styles.item]: true,
              [styles['item-active']]: isActive
            });

            const handleClick = isActive ? null : () => onActiveItemIndexChangeRequested(index);

            return (
              <div key={title} className={itemClassNames} onClick={handleClick}>
                {title}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

TabBar.propTypes = {
  activeItemIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired
  })).isRequired,
  onActiveItemIndexChangeRequested: PropTypes.func.isRequired
};

export default TabBar;
