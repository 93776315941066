import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import DocumentTitle from 'react-document-title';
import {useDispatch, useSelector} from 'react-redux';

import {createMaturityFormFields} from '../../../lib/form/fields';
import {validateAgreementAccepted} from '../../../lib/form/validators';

import useModal from '../../../hooks/use-modal';

import Maturity from '../../../redux/ducks/maturity';

import Button from '../../common/button';
import ClickableText from '../../common/clickable-text';
import Form from '../../common/form';
import Modal from '../../common/modal';

import AuthModalContent from './auth-modal-content';
import Footer from './footer';
import Header from './header';

import styles from './styles.less';

const Page = ({authCallbackRef, children, contentUserClassName, isSfw, openMenuRef, title, userClassName}) => {
  const dispatch = useDispatch();

  const {isMature} = useSelector(state => state.maturity);
  const {isLoggingIn, isRegistering, isSendingPasswordResetEmail} = useSelector(state => state.auth);

  const [authMode, setAuthMode] = useState(null);

  const [maturityModalProps, {closeModal: closeMaturityModal}] = useModal({
    isCloseOnClickDisabled: true,
    isVisible: !isSfw && !isMature
  });
  const [notMatureModalProps, {openModal: openNotMatureModal}] = useModal();
  const [authModalProps, {
    closeModal: closeAuthModal,
    openModal: openAuthModal
  }] = useModal();

  const submitCallbackRef = useRef(null);

  const handleAuthRequested = useCallback((am = null) => {
    setAuthMode(am);

    openAuthModal();
  }, []);

  useEffect(() => {
    if (!authCallbackRef) {
      return;
    }

    authCallbackRef.current = handleAuthRequested;
  }, [authCallbackRef]);

  const fields = createMaturityFormFields({
    acceptLabel: (
      <div>
        Я прочитал и принимаю
        условия <ClickableText href="/agreement" target="_blank" text="Пользовательского соглашения"/>
      </div>
    )
  });

  const handleSubmit = () => {
    dispatch(Maturity.actions.setMature(true));

    closeMaturityModal();
  };

  const page = (
    <div className={classNames(styles.page, userClassName)}>
      <Header openMenuRef={openMenuRef} title={title || 'Slut Machine'} onAuthRequested={handleAuthRequested}/>
      <main className={classNames(styles.content, contentUserClassName)}>
        {(isSfw || isMature) && children}
        <Modal {...maturityModalProps}>
          <div className={styles['maturity-modal-content']}>
            <div className={styles['maturity-modal-content-title']}>Вам уже есть 18 лет?</div>
            <div className={styles['maturity-modal-content-button-container']}>
              <Button text="Нет" userClassName={styles['maturity-button-no']} onClick={openNotMatureModal}/>
              <Button
                kind={Button.KIND.PRIMARY}
                text="Да"
                userClassName={styles['maturity-button-yes']}
                onClick={() => submitCallbackRef.current()}
              />
            </div>
            <div className={styles['maturity-form-wrapper']}>
              <Form
                submitCallbackRef={submitCallbackRef}
                fields={fields}
                validate={validateAgreementAccepted}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </Modal>
        <Modal {...notMatureModalProps}>
          <div className={styles['not-mature-modal-content']}>
            Если вам ещё нет 18 лет, немедленно покиньте данный сайт!
          </div>
        </Modal>
        <Modal {...authModalProps} isCloseOnClickDisabled={isLoggingIn || isRegistering || isSendingPasswordResetEmail}>
          <AuthModalContent mode={authMode} onCloseRequested={closeAuthModal} onModeChange={setAuthMode}/>
        </Modal>
      </main>
      <Footer/>
    </div>
  );

  if (!title) {
    return page;
  }

  return (
    <DocumentTitle title={title}>
      {page}
    </DocumentTitle>
  );
};

Page.propTypes = {
  authCallbackRef: PropTypes.object,
  children: PropTypes.node.isRequired,
  contentUserClassName: PropTypes.string,
  isSfw: PropTypes.bool,
  openMenuRef: PropTypes.object,
  title: PropTypes.string,
  userClassName: PropTypes.string
};

Page.defaultProps = {
  authCallbackRef: null,
  contentUserClassName: null,
  isSfw: false,
  openMenuRef: null,
  title: null,
  userClassName: null
};

export default Page;
