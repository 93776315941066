import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

const Image = ({data, extent, height, title, width}) => {
  const style = {};

  if (typeof extent === 'number') {
    style.height = `${extent}px`;
    style.width = `${extent}px`;
  }

  if (typeof width === 'number') {
    style.width = `${width}px`;
  }

  if (typeof height === 'number') {
    style.height = `${height}px`;
  }

  return (
    <img className={styles.image} src={data} style={style} title={title}/>
  );
};

Image.propTypes = {
  data: PropTypes.string.isRequired,
  extent: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number
};

Image.defaultProps = {
  extent: null,
  height: null,
  title: '',
  width: null
};

export default Image;
