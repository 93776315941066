import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import styles from './styles.less';

const Modal = ({children, isCloseOnClickDisabled, isVisible, onCloseRequested}) => {
  const refOverlay = useRef(null);

  const handleClick = e => {
    e.stopPropagation();

    if (isCloseOnClickDisabled || (e.target !== refOverlay.current)) {
      return;
    }

    if (onCloseRequested) {
      onCloseRequested();
    }
  };

  const overlayClassNames = classNames({
    [styles.overlay]: true,
    [styles['overlay-not-clickable']]: isCloseOnClickDisabled
  });
  const overlayStyle = isVisible ? {} : {display: 'none'};

  return (
    <div ref={refOverlay} className={overlayClassNames} style={overlayStyle} onClick={handleClick}>
      <div className={styles.modal}>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isCloseOnClickDisabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  onCloseRequested: PropTypes.func
};

Modal.defaultProps = {
  isCloseOnClickDisabled: false,
  isVisible: false,
  onCloseRequested: null
};

export default Modal;
