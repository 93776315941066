import {resubscribe} from '@ololoepepe/redux-api';

import {put, takeLatest} from 'redux-saga/effects';

import Tasks from '../ducks/tasks';
import User from '../ducks/user';

const doResubscribe = duck => function * () {
  const ducks = Array.isArray(duck) ? duck : [duck];

  const duckNames = ducks.map(d => d.subnamespace.toString());

  yield put(resubscribe({duckNames}));
};

export default function * session() {
  yield takeLatest([User.actionTypes.set.ACTION, User.actionTypes.editSingle.ACTION], doResubscribe(Tasks));
}
