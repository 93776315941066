// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/BalsamiqSans-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/BalsamiqSans-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Balsamiq Sans;font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:Balsamiq Sans;font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}body{font-family:Balsamiq Sans,sans-serif;font-size:16px}", "",{"version":3,"sources":["webpack://src/css/fonts.less","webpack://src/css/index.less"],"names":[],"mappings":"AAAA,WACE,yBAA0B,CAC1B,iBAAkB,CAClB,eAAgB,CAChB,8DACF,CAEA,WACE,yBAA0B,CAC1B,iBAAkB,CAClB,eAAgB,CAChB,8DACF,CCXA,KAEE,oCAAsC,CACtC,cACF","sourcesContent":["@font-face {\n  font-family: Balsamiq Sans;\n  font-style: normal;\n  font-weight: 400;\n  src: url('../assets/fonts/BalsamiqSans-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: Balsamiq Sans;\n  font-style: normal;\n  font-weight: 700;\n  src: url('../assets/fonts/BalsamiqSans-Bold.ttf') format('truetype');\n}\n","@global-import './fonts.less';\nbody {\n  /* Workaround to include fonts */\n  font-family: Balsamiq Sans, sans-serif;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
