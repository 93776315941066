import React from 'react';

import Page from '../../common/page';

import styles from './styles.less';

const AgreementPage = () => {
  return (
    <Page isSfw title="Пользовательское соглашение">
      <div className={styles.content}>
        <p className={styles.header}>
          Пользовательское соглашение
        </p>
        <p>
          Настоящее Соглашение определяет условия использования Пользователями веб-сайта Slut Machine (далее — «Сайт»).
        </p>
        <p className={styles.header}>
          1. Общие условия
        </p>
        <p>
          1.1. Любое использование материалов и сервисов Сайта регулируется нормами действующего законодательства
          Российской Федерации.
        </p>
        <p>
          1.2. Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Пользователь считается
          присоединившимся к настоящему Соглашению.
        </p>
        <p>
          1.3. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения.
          Такие изменения вступают в силу по истечении 3 (Трех) дней с момента публикации версии Сайта с новым
          Соглашением. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту,
          прекратить использование материалов и сервисов Сайта.
        </p>
        <p className={styles.header}>
          2. Обязательства Пользователя
        </p>
        <p>
          2.1. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие
          российское законодательство или нормы международного права, в том числе в сфере интеллектуальной
          собственности, авторских и/или смежных правах, а также любых действий, которые приводят или могут привести к
          нарушению нормальной работы сервисов Сайта.
        </p>
        <p>
          2.2. Использование материалов Сайта в печатных СМИ допускается только с письменного согласия правообладателя
          (статья 1270 ГК РФ).
        </p>
        <p>
          2.3. При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна
          (подпункт 1 пункта 1 статьи 1274 ГК РФ), причем ссылка должна быть активной, иметь внешний вид ссылки, и не
          закрытой от индексации поисковыми системами.
        </p>
        <p>
          2.4. Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и
          использование им внешних ресурсов, ссылки на которые могут содержаться на Сайте.
        </p>
        <p>
          2.5. Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или
          косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками,
          связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами
          или услугами, доступными на или полученными через внешние сайты или ресурсы либо иные контакты Пользователя,
          в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
        </p>
        <p>
          2.6. Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут
          сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо
          ответственности и не имеет каких-либо обязательств в связи с такой рекламой.
        </p>
        <p className={styles.header}>
          3. Прочие условия
        </p>
        <p>
          3.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в
          соответствии с действующим законодательством Российской Федерации.
        </p>
        <p>
          3.2. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрацией Сайта
          агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма,
          либо каких-то иных отношений, прямо не предусмотренных Соглашением.
        </p>
        <p>
          3.3. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному
          исполнению не влечет недействительности иных положений Соглашения.
        </p>
        <p>
          3.4. Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений
          Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих
          интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта.
        </p>
        <p className={styles.header}>
          4. Отказ от ответственности
        </p>
        <p>
          4.1. Размещаемая на Сайте информация предназначена для свободного ознакомления с ней пользователей.
        </p>
        <p>
          4.2. Вся информация на Сайте предоставляется без каких-либо указаний, просьб или требований к выполнению
          каких-либо действий.
        </p>
        <p>
          4.3. Выполняя задания эротического и сексуального характера, содержащиеся на Сайте, Пользователь берет на себя
          всю ответственность за возможные последствия, в том числе травмы, увечья, нарушение трудоспособности и т.д.
          Администрация Сайта не несет никакой ответственности за действия пользователей.
        </p>
        <p>
          4.4. Сайт и его Содержимое предоставляются свободно, как акт доброй воли, по принципу «как есть», без
          заключения каких-либо договорённостей или договоров между Вами, пользователями данного Сайта, Администрацией,
          владельцами серверов, на которых размещены сервисы Сайта, либо кем-то ещё, любым образом связанными с этим или
          родственными ему проектами, которые [договора] могут стать предметом прямых претензий.
        </p>
        <p>
          4.5. Администрация не дает каких-либо заверений или гарантий в отношении Сайта и его Содержимого, в отношении
          того, что при использовании Сайта не возникнет ошибок, оно будет безопасным и бесперебойным, что Администрация
          будет исправлять какие-либо ошибки, а так же, что Содержимое и Сайт не нарушают прав третьих лиц.
        </p>
        <p>
          4.6. Некоторые ссылки на этом Сайте могут вести к ресурсам, расположенным на сторонних сайтах. Данные ссылки
          размещены для удобства пользователей и не означают, что Администрация одобряет содержание других сайтов. Кроме
          этого, Администрация Сайта не несет никакой ответственности за доступность этих ресурсов и за их контент. Это
          заявление относится ко всем ссылкам, представленным на Сайте.
        </p>
        <p>
          4.7. В соответствии с действующим законодательством, Администрация отказывается от каких-либо заверений и
          гарантий, предоставление которых может иным образом подразумеваться, и отказываются от ответственности в
          отношении Сайта, Содержимого и их использования. Ни при каких обстоятельствах Администрация Сайта не будет
          нести ответственности ни перед какой стороной за какой-либо прямой, непрямой, особый или иной косвенный ущерб
          в результате любого использования информации на этом Сайте или на любом сайте, на который имеется гиперссылка
          на нашем Сайте, возникновение зависимости, снижения продуктивности, увольнения или прерывания трудовой
          активности, а равно и отчисления из учебных учреждений, за любую упущенную выгоду, приостановку хозяйственной
          деятельности, потерю программ или данных в Ваших информационных системах или иным образом, возникшие в связи с
          доступом, использованием или невозможностью использования Сайта, Содержимого или какого-либо связанного
          интернет-сайта, или неработоспособностью, ошибкой, упущением, перебоем, дефектом, простоем в работе или
          задержкой в передаче, компьютерным вирусом или системным сбоем, даже если Администрация будет явно поставлена
          в известность о возможности такого ущерба.
        </p>
        <p>
          4.8. За рекламу, размещаемую на Сайте, несет ответственность лишь рекламодатель. Сайт особо уведомляет о том,
          что не гарантирует возможность приобретения или использования тех или иных товаров или услуг по ценам и/или на
          условиях, указываемых в рекламных блоках. Вы соглашаетесь с тем, что Администрация Сайта не несет никакой
          ответственности за возможные последствия (включая любой ущерб), возникшие в результате каких-либо отношений с
          рекламодателями.
        </p>
        <p>
          4.9. Администрация Сайта оставляет за собой право вносить изменения без уведомления о них пользователей.
        </p>
        <p>
          4.10. Администрация вправе отказать в доступе к Сайту любому Пользователю, или группе Пользователей без
          объяснения причин своих действий и предварительного уведомления.
        </p>
        <p>
          4.11. Любые торговые марки, знаки и названия товаров, служб и организаций, права на дизайн, авторские и
          смежные права, которые упоминаются, используются или цитируются на страницах Сайта, принадлежат их законным
          владельцам и их использование здесь не дает вам право на любое другое использование. Если не указано иное,
          страницы данного Сайта никак не связаны с правообладателями, и никто кроме правообладателя не может
          распоряжаться правами на использование материалов, защищенных авторским правом. Вы несете ответственность за
          использование этих и подобных материалов.
        </p>
        <p>
          4.12. Чтение, распространение или изменение информации, размещённой на данном сайте, может являться нарушением
          законов той страны, в которой вы просматриваете этот сайт.
        </p>
        <p>
          4.13. Пользователь соглашается с тем, что все возможные споры будут разрешаться по нормам российского права.
        </p>
        <p>
          4.14. Пользователь соглашается с тем, что нормы и законы о защите прав потребителей не могут быть применимы к
          использованию им Сайта, поскольку он не оказывает возмездных услуг.
        </p>
        <p>
          4.15. Бездействие со стороны Администрации Сайта в случае нарушения Пользователем либо группой Пользователей
          пользовательского соглашения не лишает Администрацию права предпринять соответствующие действия в защиту
          интересов Сайта позднее.
        </p>
        <p>
          4.16. Все права на материалы, находящиеся на Сайте, охраняются в соответствии с законодательством ЕС и РФ, в
          том числе, об авторском праве и смежных правах.
        </p>
        <p>
          4.17. Компоновка экранов, логотип, графики и рисунки, размещенные на данном Сайте, защищены авторским правом.
        </p>
        <p>
          4.18. Если в соответствии с действующими законами какие-либо условия будут признаны недействительными,
          остальные условия остаются в полной силе.
        </p>
        <p className={styles.header}>
          Используя данный Сайт, Вы выражаете свое согласие с «Отказом от ответственности» и установленными Правилами
          и принимаете всю ответственность, которая может быть на Вас возложена.
        </p>
      </div>
    </Page>
  );
};

export default AgreementPage;
