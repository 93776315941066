export function getFormState(instance) {
  const {meta, values} = instance;

  const fieldMeta = Object.keys(values).reduce((acc, key) => {
    acc[key] = instance.getFieldMeta(key);

    return acc;
  }, {});

  return {fieldMeta, meta, values};
}

export const handleSubmitFailure = getErrors => (err, setError) => {
  if (!err) {
    return;
  }

  const submitErrors = getErrors(err);

  Object.keys(submitErrors).forEach(key => {
    setError(key, submitErrors[key]);
  });
};

export function setFormState(instance, state) {
  const {fieldMeta, meta, values} = state;

  instance.setValues(values);
  instance.setMeta(meta);

  Object.keys(fieldMeta).forEach(key => {
    instance.setFieldMeta(key, fieldMeta[key]);
  });
}
