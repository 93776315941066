import React from 'react';

import ClickableText from '../../common/clickable-text';
import Page from '../../common/page';

const {version} = require('../../../../package');

import styles from './styles.less';

const AboutPage = () => {
  return (
    <Page title="О проекте">
      <div className={styles.content}>
        <p className={styles.header}>
          Slut Machine v{version}
        </p>
        <p>
          Новая эротическая игра 18+ «Slut Machine: секс-задания для пар».<br/>Окунитесь в мир страсти и
          безумия! <span className={styles.emoji}>❤️💋</span>
        </p>
        <p>
          Slut Machine предлагает партнёрам выполнять случайным образом выбранные задания (фанты) эротического и
          сексуального характера.
        </p>
        <p>
          Приложение отлично подойдёт как для пар, начинающих познавать основы секса, так и для партнёров, которые уже
          имеют многолетний опыт, но устали от однообразия.
        </p>
        <p className={styles.header}>
          Задания и фетиши
        </p>
        <p>
          Игра включает в себя 165 заданий 18+, которые можно использовать как прелюдию к сексу или руководство к
          основному действию.<br/>Вам больше не придётся скрывать свои желания от второй половинки. Стоит только выбрать
          нужные фетиши — и задания подберутся индивидуально под ваши предпочтения.
        </p>
        <p>
          В стандартном наборе фетишей присутствуют:
        </p>
        <ul className={styles.list}>
          <li>- доминация;</li>
          <li>- подчинение;</li>
          <li>- минет;</li>
          <li>- кунилингус;</li>
          <li>- футфетиш;</li>
          <li>- секс-игрушки.</li>
        </ul>
        <p>
          Для любителей необычных ощущений есть премиумные пакеты заданий «Капельки» и «Попка», которые включают в себя:
        </p>
        <ul className={styles.list}>
          <li>- «золотой дождь»;</li>
          <li>- анальный секс;</li>
          <li>- анилингус;</li>
          <li>- игры с едой, слюной, спермой;</li>
          <li>и многое другое.</li>
        </ul>
        <p className={styles.header}>
          Настройки
        </p>
        <p>
          Не бойтесь наткнуться на неприемлемое для вас или вашего партнёра задание: в настройках приложения есть
          возможность фильтрации допустимых действий и фетишей. Также вы можете в любой момент скрыть непонравившееся
          задание.<br/>Используйте фанты для старта, а дальше фантазируйте и продолжайте свои эксперименты в постели.
        </p>
        <p className={styles.header}>
          Особенности
        </p>
        <p>
          Игра представляет собой слот-машину, более извустную русскоязычной аудитории как «однорукий бандит», однако
          вместо традиционных слотов выпадают изображения эротической тематики. В зависимости от выпавших комбинаций
          приложение предлагает выполнить соответствующее задание.
        </p>
        <p>
          Наша цель — отыскать индивидуальный подход ко всем пользователям и помочь им раскрепоститься.
        </p>
        <p>
          А теперь прекращайте это читать! Скорее бегите заниматься любовью.
        </p>
        <p>
          P.S. Все задания проверены разработчиками на личном опыте. В ходе испытаний никто не пострадал :)️
        </p>
        <p className={styles.header}>
          Расширяйте границы воображения вместе с секс-фантами. Позвольте удовольствию поглотить
          вас! <span className={styles.emoji}>❤</span>
        </p>
        <p>
          <ClickableText href="/agreement" text="Пользовательское соглашение" target="_blank"/>
        </p>
      </div>
    </Page>
  );
};

export default AboutPage;
