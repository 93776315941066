import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {parseTaskId} from '../../../lib/game';

import Settings from '../../../redux/ducks/settings';

import CheckboxFormField from '../../common/form/form-field/checkbox';
import Page from '../../common/page';

import styles from './styles.less';

const BlacklistPage = () => {
  const dispatch = useDispatch();

  const {items: tasks} = useSelector(state => state.tasks);
  const {restrictedTaskIds} = useSelector(state => state.settings);

  const localRestrictedTaskIds = useMemo(() => restrictedTaskIds, []);

  const restrictedTasks = localRestrictedTaskIds.map(id => {
    const {index, symbols} = parseTaskId(id);

    const symbolsString = symbols.join('-');

    const task = tasks.find(t => t.symbols.join('-') === symbolsString).tasks[index];

    return {
      ...task,
      id
    };
  });

  const handleCheckTask = id => isChecked => {
    dispatch(Settings.actions[isChecked ? 'addRestrictedTaskId' : 'removeRestrictedTaskId'](id));
  };

  const contentClassNames = classNames({
    [styles.content]: true,
    [styles['content-empty']]: restrictedTasks.length < 1
  });

  return (
    <Page title="Чёрный список">
      <div className={contentClassNames}>
        {
          (restrictedTasks.length < 1) && (
            <div className={styles.dummy}>
              <p>Чёрный список пуст.</p>
              <p>Надеемся, вам понравятся все задания.</p>
              <p>:)</p>
            </div>
          )
        }
        {
          restrictedTasks
            .map(({id, text}) => {
              const label = (
                <div className={styles.label} title={text}>{text}</div>
              );

              return (
                <div key={id} className={styles.item}>
                  <CheckboxFormField
                    isChecked={restrictedTaskIds.includes(id)}
                    label={label}
                    name={id}
                    setTouched={() => {}}
                    setValue={handleCheckTask(id)}
                  />
                </div>
              );
            })
        }
      </div>
    </Page>
  );
};

export default BlacklistPage;
