import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getRandomSymbols} from '../lib/game';

const IMAGE_GENERATION_INTERVAL = 200;

const useRandomSymbols = (isActive = false) => {
  const premiumSymbols = useSelector(state => state.userProducts).items
    .filter(({type}) => type === 'symbol')
    .map(({name}) => name);

  const [randomSymbols, setRandomSymbols] = useState(getRandomSymbols(premiumSymbols));

  useEffect(() => {
    if (!isActive) {
      return;
    }

    const timer = setInterval(() => {
      setRandomSymbols(getRandomSymbols(premiumSymbols));
    }, IMAGE_GENERATION_INTERVAL);

    return () => clearInterval(timer);
  }, [isActive, JSON.stringify(premiumSymbols)]);

  return randomSymbols;
};

export default useRandomSymbols;
