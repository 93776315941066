import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GENDERS} from '../../../lib/constants';
import {purchase} from '../../../lib/purchase';

import Settings from '../../../redux/ducks/settings';

import useModal from '../../../hooks/use-modal';

import Button from '../../common/button';
import CheckboxFormField from '../../common/form/form-field/checkbox';
import Modal from '../../common/modal';
import Page from '../../common/page';
import {AuthMode} from '../../common/page/auth-modal-content';
import TabBar from '../../common/tab-bar';

import styles from './styles.less';

const {fetishes} = require('../../../assets/data/fetishes.json');
const {symbols} = require('../../../assets/data/symbols.json');

const SettingsPage = () => {
  const dispatch = useDispatch();

  const {restrictedFetishes, selectedFetishesOnly} = useSelector(state => state.settings);
  const {items: userProducts} = useSelector(state => state.userProducts);

  const authCallbackRef = useRef(null);

  const [productModalProps, {openModal: openProductModal}] = useModal();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [productModalOptions, setProductModalOptions] = useState(null);

  const items = [{title: 'Парень'}, {title: 'Девушка'}];

  const activeGender = GENDERS[activeTabIndex];

  fetishes.filter(({genders}) => genders.includes(activeGender));

  const availablePremiumSymbols = userProducts
    .filter(({type}) => type === 'symbol')
    .map(({name}) => name);

  const availableOptions = userProducts
    .filter(({type}) => type === 'option')
    .map(({name}) => name);

  const handleCheckFetish = (name, isAvailable, premiumSymbols) => isChecked => {
    if (!isAvailable) {
      const {title} = symbols.find(symbol => symbol.name === premiumSymbols[0]).pack;

      setProductModalOptions({
        buttonText: 'Купить пакет',
        product: {
          name: premiumSymbols[0],
          type: 'symbol'
        },
        text: `Чтобы разблокировать фетиш, приобретите пакет заданий «${title}».`
      });

      openProductModal();

      return;
    }

    dispatch(Settings.actions[isChecked ? 'removeRestrictedFetish' : 'addRestrictedFetish']({
      name,
      gender: activeGender
    }));
  };

  const handleCheckSelectedFetishesOnly = isChecked => {
    const isAvailable = availableOptions.includes('selected_fetishes_only');

    if (!isAvailable) {
      setProductModalOptions({
        buttonText: 'Купить опцию',
        product: {
          name: 'selected_fetishes_only',
          type: 'option'
        },
        text: 'Сосредоточьтесь на ваших сексуальных желаниях, оставив только задания с любимыми фетишами.'
      });

      openProductModal();

      return;
    }

    dispatch(Settings.actions.setSelectedFetishesOnly({
      enabled: isChecked,
      gender: activeGender
    }));
  };

  const handleBuyButtonClick = () => {
    if (!productModalOptions) {
      return;
    }

    purchase(productModalOptions.product, () => {
      if (authCallbackRef.current) {
        authCallbackRef.current(AuthMode.SignUp);
      }
    });
  };

  return (
    <Page authCallbackRef={authCallbackRef} title="Настройки">
      <div className={styles.content}>
        <TabBar activeItemIndex={activeTabIndex} items={items} onActiveItemIndexChangeRequested={setActiveTabIndex}/>
        <div className={styles.settings}>
          <div className={styles['section-title']}>Допустимые фетиши</div>
          <div className={styles['section-body']}>
            {
              fetishes
                .sort((f1, f2) => f1.premiumSymbols.length - f2.premiumSymbols.length)
                .filter(({genders}) => genders.includes(activeGender))
                .map(({badges, name, premiumSymbols, title}) => {
                  const label = (
                    <div className={styles.label}>
                      {title}
                      {
                        (badges.length > 0) && (
                          <sup className={styles.badge}>{badges.map(badge => badge.toUpperCase()).join(', ')}</sup>
                        )
                      }
                    </div>
                  );

                  const isAvailable = premiumSymbols.every(symbol => availablePremiumSymbols.includes(symbol));

                  return (
                    <div key={name} className={styles.item}>
                      <CheckboxFormField
                        isChecked={isAvailable && !restrictedFetishes[activeGender].includes(name)}
                        isDisabled={false}
                        label={label}
                        name={name}
                        setTouched={() => {}}
                        setValue={handleCheckFetish(name, isAvailable, premiumSymbols)}
                      />
                    </div>
                  );
                })
            }
          </div>
          <div className={styles['section-title']}>Прочее</div>
          <div className={styles['section-body']}>
            <div className={styles.item}>
              <CheckboxFormField
                isChecked={selectedFetishesOnly[activeGender]}
                isDisabled={false}
                label={(
                  <div className={styles.label}>Скрыть задания без фетишей</div>
                )}
                name="selected_fetishes_only"
                setTouched={() => {}}
                setValue={handleCheckSelectedFetishesOnly}
              />
            </div>
          </div>
        </div>
        <Modal {...productModalProps}>
          <div className={styles['product-modal-content']}>
            <div>{productModalOptions && productModalOptions.text}</div>
            <div className={styles['product-modal-button-wrapper']}>
              <Button
                kind={Button.KIND.PRIMARY}
                text={productModalOptions ? productModalOptions.buttonText : ''}
                onClick={handleBuyButtonClick}
              />
            </div>
          </div>
        </Modal>
      </div>
    </Page>
  );
};

export default SettingsPage;
