import {RX_EMAIL} from '../constants';

export function validateAgreementAccepted({accept}) {
  if (!accept) {
    return {_error: 'Чтобы продолжить, необходимо принять\nусловия Пользовательского соглашения'};
  }

  return {_error: null};
}

export function validateEmail(value) {
  if (!value) {
    return 'Необходимо указать электронную почту';
  }

  if (!RX_EMAIL.test(value)) {
    return 'Неверный формат электронной почты';
  }

  return false;
}

export function validatePassword(value) {
  if (!value) {
    return 'Необходимо указать пароль';
  }

  if (value.length < 8) {
    return 'Пароль слишком короткий';
  }

  return false;
}

export function validatePasswordsMatch(values) {
  if (values.password !== values.password2) {
    return {password2: 'Пароли должны совпадать'};
  }

  return {password2: null};
}
