import {createDuck} from '../../lib/firebase-provider';

const {tasks} = require('../../assets/data/tasks.json');

const TASKS = tasks.map(task => ({
  ...task,
  id: task.symbols.join('-')
}));

export default createDuck('tasks', {
  collectionName: 'tasks',
  createQuery: (collection, {state}) => {
    if (!state.user.item.hasProducts) {
      return null;
    }

    return collection.where('premiumSymbols', '>', ''); // TODO: Add isPremium flag
  }
}, {
  reducerOptions: {
    customInitialState: () => ({
      countTotal: TASKS.length,
      items: TASKS
    })
  }
});
