// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Tz2mt7j{-js-display:flex;display:flex;justify-content:space-between;flex-shrink:0;height:48px;width:100%;padding:0 16px;box-sizing:border-box;background-color:#d50000;color:#fafafa}._1iDHAKHM{font-size:12px;text-decoration:none;color:#fafafa}.P6nl7Bma{vertical-align:middle}._3g0O4Tq-{font-size:12px;text-decoration:none;color:#fafafa}._2UkBCq-x{-js-display:flex;display:flex;flex-direction:column;justify-content:center;height:100%}", "",{"version":3,"sources":["webpack://src/components/common/page/footer/styles.less"],"names":[],"mappings":"AAAA,WACE,gBAAa,CAAb,YAAa,CACb,6BAA8B,CAC9B,aAAc,CACd,WAAY,CACZ,UAAW,CACX,cAAe,CACf,qBAAsB,CACtB,wBAAyB,CACzB,aACF,CACA,WACE,cAAe,CACf,oBAAqB,CACrB,aACF,CACA,UACE,qBACF,CACA,WACE,cAAe,CACf,oBAAqB,CACrB,aACF,CACA,WACE,gBAAa,CAAb,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,WACF","sourcesContent":[".component {\n  display: flex;\n  justify-content: space-between;\n  flex-shrink: 0;\n  height: 48px;\n  width: 100%;\n  padding: 0 16px;\n  box-sizing: border-box;\n  background-color: #d50000;\n  color: #fafafa;\n}\n.copyright-link {\n  font-size: 12px;\n  text-decoration: none;\n  color: #fafafa;\n}\n.email-icon {\n  vertical-align: middle;\n}\n.email-link {\n  font-size: 12px;\n  text-decoration: none;\n  color: #fafafa;\n}\n.left-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_3Tz2mt7j",
	"copyright-link": "_1iDHAKHM",
	"email-icon": "P6nl7Bma",
	"email-link": "_3g0O4Tq-",
	"left-container": "_2UkBCq-x"
};
export default ___CSS_LOADER_EXPORT___;
