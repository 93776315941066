import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

const subnamespace = defineAction('guide');

const SET_GUIDE_ITEM_COMPLETED = defineAction('SET_GUIDE_ITEM_COMPLETED', subnamespace);

const setGuideItemCompleted = createAction(SET_GUIDE_ITEM_COMPLETED.ACTION);

const INITIAL_STATE = {
  completedItems: []
};

function handleSetGuideItemCompleted(state, {payload}) {
  const newItems = [...state.completedItems];

  if (newItems.includes(payload)) {
    return state;
  }

  newItems.push(payload);

  return {
    ...state,
    completedItems: newItems
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    SET_GUIDE_ITEM_COMPLETED
  },
  actions: {
    setGuideItemCompleted
  },
  reducer: createReducer(INITIAL_STATE, {
    [SET_GUIDE_ITEM_COMPLETED.ACTION]: handleSetGuideItemCompleted
  })
};

export default DUCK;
