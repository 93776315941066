import React from 'react';
import DocumentTitle from 'react-document-title';
import {Redirect, Router, Route, Switch} from 'react-router-dom';

import history from '../../lib/history';

import AboutPage from '../pages/about';
import AgreementPage from '../pages/agreement';
import BlacklistPage from '../pages/blacklist';
import GamePage from '../pages/game';
import NotFoundPage from '../pages/not-found';
import PacksPage from '../pages/packs';
import SettingsPage from '../pages/settings';

const App = () => {
  return (
    <DocumentTitle title="Slut Machine">
      <Router history={history}>
        <Switch>
          <Route exact path="/" render={() => (<Redirect to="/game"/>)}/>
          <Route exact path="/about" component={AboutPage}/>
          <Route exact path="/agreement" component={AgreementPage}/>
          <Route exact path="/blacklist" component={BlacklistPage}/>
          <Route exact path="/game" component={GamePage}/>
          <Route exact path="/packs" component={PacksPage}/>
          <Route exact path="/settings" component={SettingsPage}/>
          <Route component={NotFoundPage}/>
        </Switch>
      </Router>
    </DocumentTitle>
  );
};

export default App;
