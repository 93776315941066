// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Cw9YvP1{padding:4px;-js-display:flex;display:flex;justify-content:center;align-items:center;border:1px solid #000}._2VKIFlpd{margin:0;padding:0}._2VKIFlpd:first-child{padding-right:5px}._2VKIFlpd:nth-child(2){padding-left:5px;padding-right:5px}._2VKIFlpd:nth-child(3){padding-left:5px}.NaX5UdPm{width:100%;-js-display:flex;display:flex;justify-content:center;align-items:center}.PCPliUwD{margin:0;padding:10px 0}", "",{"version":3,"sources":["webpack://src/components/pages/game/slots/styles.less"],"names":[],"mappings":"AAAA,WACE,WAAY,CACZ,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,qBACF,CACA,WACE,QAAS,CACT,SACF,CACA,uBACE,iBACF,CACA,wBACE,gBAAiB,CACjB,iBACF,CACA,wBACE,gBACF,CACA,UACE,UAAW,CACX,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBACF,CACA,UACE,QAAS,CACT,cACF","sourcesContent":[".slot {\n  padding: 4px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #000;\n}\n.slot-container {\n  margin: 0;\n  padding: 0;\n}\n.slot-container:nth-child(1) {\n  padding-right: 5px;\n}\n.slot-container:nth-child(2) {\n  padding-left: 5px;\n  padding-right: 5px;\n}\n.slot-container:nth-child(3) {\n  padding-left: 5px;\n}\n.slots {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.slots-container {\n  margin: 0;\n  padding: 10px 0 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slot": "_1Cw9YvP1",
	"slot-container": "_2VKIFlpd",
	"slots": "NaX5UdPm",
	"slots-container": "PCPliUwD"
};
export default ___CSS_LOADER_EXPORT___;
