import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FieldLabel from '../../field-label';

import styles from './styles.less';

const TextareaFormField = props => {
  const {
    isDisabled,
    isTouched,
    label,
    name,
    placeholder,
    rowCount,
    error,
    value,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(isTouched && error);

  const textareaClassNames = classNames({
    [styles.textarea]: true,
    [styles.disabled]: isDisabled,
    [styles['no-resize']]: rowCount > 0,
    [styles['with-error']]: hasError
  });

  const textareaProps = {
    name,
    value,
    className: textareaClassNames,
    disabled: isDisabled,
    onBlur: () => setTouched(),
    onChange: e => {
      const {value} = e.target;

      if (onChange) {
        onChange(value, setValue);
      } else {
        setValue(value);
      }
    }
  };

  if (placeholder) {
    textareaProps.placeholder = placeholder;
  }

  if (rowCount > 0) {
    textareaProps.rows = rowCount;
  }

  return (
    <div className={styles['textarea-form-field']}>
      {
        (Boolean(label) || hasError) && (
          <FieldLabel isError={hasError} text={hasError ? error : label}/>
        )
      }
      <textarea {...textareaProps}/>
    </div>
  );
};

TextareaFormField.propTypes = {
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rowCount: PropTypes.number,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

TextareaFormField.defaultProps = {
  error: null,
  isDisabled: false,
  isTouched: false,
  label: null,
  placeholder: null,
  rowCount: null,
  value: '',
  onChange: null
};

export default TextareaFormField;
