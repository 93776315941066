// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qU-KCMY{white-space:pre-wrap;line-height:1.5;padding:20px}._2ZPeNKD3{font-size:20px;font-weight:700}", "",{"version":3,"sources":["webpack://src/components/pages/agreement/styles.less"],"names":[],"mappings":"AAAA,WACE,oBAAqB,CACrB,eAAgB,CAChB,YACF,CACA,WACE,cAAe,CACf,eACF","sourcesContent":[".content {\n  white-space: pre-wrap;\n  line-height: 1.5;\n  padding: 20px;\n}\n.header {\n  font-size: 20px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_2qU-KCMY",
	"header": "_2ZPeNKD3"
};
export default ___CSS_LOADER_EXPORT___;
