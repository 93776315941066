import {getSourceAction} from '@ololoepepe/redux-tools';

import {put, takeLatest} from 'redux-saga/effects';

import Auth from '../ducks/auth';
import Session from '../ducks/session';

function * handleLogIn() {
  yield put(Session.actions.logIn());
}

function * handleLogOut() {
  yield put(Session.actions.logOut());
}

function * handleRegister({payload}) {
  yield put(Auth.actions.logInEmailPassword(getSourceAction(payload)));
}

export default function * auth() {
  yield takeLatest(Auth.actionTypes.logInEmailPassword.SUCCESS, handleLogIn);
  yield takeLatest(Auth.actionTypes.signInGoogle.SUCCESS, handleLogIn);
  yield takeLatest(Auth.actionTypes.logOut.SUCCESS, handleLogOut);
  yield takeLatest(Auth.actionTypes.logOut.ERROR, handleLogOut);
  yield takeLatest(Auth.actionTypes.registerEmailPassword.SUCCESS, handleRegister);
}
