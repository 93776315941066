// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3VRGHyhx{width:100%;padding:9px 15px 8px;box-sizing:border-box;border-radius:4px;background-color:#fafafa;font-size:16px;font-family:Balsamiq Sans,sans-serif;color:#000;resize:vertical}._3VRGHyhx.g2mgEZBH{font-size:14px}._3VRGHyhx:focus{outline:0}._3VRGHyhx::-moz-placeholder{opacity:.25;font-size:16px;font-family:Balsamiq Sans,sans-serif;line-height:1.36;color:#000}._3VRGHyhx::placeholder{opacity:.25;font-size:16px;font-family:Balsamiq Sans,sans-serif;line-height:1.36;color:#000}._3VRGHyhx.U8G-F8rn{resize:none}._2cZuQf45{display:inline-block;width:100%}", "",{"version":3,"sources":["webpack://src/components/common/form/form-field/textarea/styles.less"],"names":[],"mappings":"AAAA,WACE,UAAW,CACX,oBAA0B,CAC1B,qBAAsB,CACtB,iBAAkB,CAClB,wBAAyB,CACzB,cAAe,CACf,oCAAsC,CACtC,UAAW,CACX,eACF,CACA,oBACE,cACF,CACA,iBACE,SACF,CACA,6BACE,WAAa,CACb,cAAe,CACf,oCAAsC,CACtC,gBAAiB,CACjB,UACF,CANA,wBACE,WAAa,CACb,cAAe,CACf,oCAAsC,CACtC,gBAAiB,CACjB,UACF,CACA,oBACE,WACF,CACA,WACE,oBAAqB,CACrB,UACF","sourcesContent":[".textarea {\n  width: 100%;\n  padding: 9px 15px 8px 15px;\n  box-sizing: border-box;\n  border-radius: 4px;\n  background-color: #fafafa;\n  font-size: 16px;\n  font-family: Balsamiq Sans, sans-serif;\n  color: #000;\n  resize: vertical;\n}\n.textarea.disabled {\n  font-size: 14px;\n}\n.textarea:focus {\n  outline: 0;\n}\n.textarea::placeholder {\n  opacity: 0.25;\n  font-size: 16px;\n  font-family: Balsamiq Sans, sans-serif;\n  line-height: 1.36;\n  color: #000;\n}\n.textarea.no-resize {\n  resize: none;\n}\n.textarea-form-field {\n  display: inline-block;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "_3VRGHyhx",
	"disabled": "g2mgEZBH",
	"no-resize": "U8G-F8rn",
	"textarea-form-field": "_2cZuQf45"
};
export default ___CSS_LOADER_EXPORT___;
