import FormField from '../../components/common/form/form-field';

import {validateEmail, validatePassword} from './validators';

export function createLogInFormFields({onEmailChange}) {
  return [{
    name: 'email',
    type: 'email',
    placeholder: 'Электронная почта',
    validate: validateEmail,
    onChange: (value, cb) => {
      onEmailChange(value);

      cb(value);
    }
  }, {
    name: 'password',
    placeholder: 'Пароль (не менее 8 символов)',
    type: 'password',
    validate: validatePassword
  }];
}

export const createMaturityFormFields = ({acceptLabel}) => [{
  label: acceptLabel,
  name: 'accept',
  type: FormField.TYPE.CHECKBOX
}];

export const RESET_PASSWORD_FORM_FIELDS = [{
  name: 'email',
  type: 'email',
  placeholder: 'Электронная почта',
  validate: validateEmail
}];

export const SIGN_UP_FORM_FIELDS = [{
  name: 'email',
  type: 'email',
  placeholder: 'Электронная почта',
  validate: validateEmail
}, {
  name: 'password',
  placeholder: 'Пароль (не менее 8 символов)',
  type: 'password',
  validate: validatePassword
}, {
  name: 'password2',
  placeholder: 'Подтвердите пароль',
  type: 'password'
}];

