import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FieldLabel from '../../field-label';

import styles from './styles.less';

const InputFormField = props => {
  const {
    error,
    isDisabled,
    isTouched,
    label,
    max,
    min,
    name,
    placeholder,
    type,
    value,
    setTouched,
    setValue,
    onChange
  } = props;

  const hasError = Boolean(isTouched && error);

  const inputFormFieldClassNames = classNames({
    [styles['input-form-field']]: true,
    [styles['with-error']]: hasError
  });
  const inputClassNames = classNames({
    [styles.input]: true,
    [styles.disabled]: isDisabled,
    [styles['with-error']]: hasError
  });

  const inputProps = {
    name,
    type,
    value,
    className: inputClassNames,
    disabled: isDisabled,
    onBlur: () => setTouched(),
    onChange: e => {
      const {value} = e.target;

      if (onChange) {
        onChange(value, setValue);
      } else {
        setValue(value);
      }
    }
  };

  if (placeholder) {
    inputProps.placeholder = placeholder;
  }

  if (min !== null) {
    inputProps.min = min;
  }

  if (max !== null) {
    inputProps.max = max;
  }

  return (
    <div className={inputFormFieldClassNames}>
      {
        (Boolean(label) || hasError) && (
          <FieldLabel isError={hasError} text={hasError ? error : label} value={value}/>
        )
      }
      <input {...inputProps}/>
    </div>
  );
};

InputFormField.propTypes = {
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  setTouched: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  onChange: PropTypes.func
};

InputFormField.defaultProps = {
  error: null,
  isDisabled: false,
  isTouched: false,
  label: null,
  max: null,
  min: null,
  placeholder: null,
  value: '',
  onChange: null
};

export default InputFormField;
