// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AFzbQpo{color:#d50000}._2AFzbQpo:not(._2MMatV07){cursor:pointer}._2AFzbQpo._2MMatV07{cursor:not-allowed}._2AFzbQpo:not(:hover){text-decoration:none}", "",{"version":3,"sources":["webpack://src/components/common/clickable-text/styles.less"],"names":[],"mappings":"AAAA,WACE,aACF,CACA,2BACE,cACF,CACA,qBACE,kBACF,CACA,uBACE,oBACF","sourcesContent":[".clickable-text {\n  color: #d50000;\n}\n.clickable-text:not(.disabled) {\n  cursor: pointer;\n}\n.clickable-text.disabled {\n  cursor: not-allowed;\n}\n.clickable-text:not(:hover) {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable-text": "_2AFzbQpo",
	"disabled": "_2MMatV07"
};
export default ___CSS_LOADER_EXPORT___;
