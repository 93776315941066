import {init as initSentry} from '@sentry/react';
import {Integrations as SentryIntegrations} from '@sentry/tracing';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import 'whatwg-fetch';

import {createStore} from './redux';

import App from './components/app';

import './css/index.less';

initSentry({
  dsn: 'https://795d090619564d2882f16d2a9b6ae253@o442446.ingest.sentry.io/5414284',
  environment: process.env.SENTRY_ENVIRONMENT || 'production',
  integrations: [
    new SentryIntegrations.BrowserTracing()
  ],
  tracesSampleRate: 1.0,
  release: process.env.RELEASE
});

firebase.initializeApp({
  apiKey: 'AIzaSyCHj0UQ8AltuWk1N9Q-Ze5AW4gBxilZkrc',
  authDomain: 'slut-machine-ru-vw.firebaseapp.com',
  databaseURL: 'https://slut-machine-ru-vw.firebaseio.com',
  projectId: 'slut-machine-ru-vw',
  storageBucket: 'slut-machine-ru-vw.appspot.com',
  messagingSenderId: '99785780028',
  appId: '1:99785780028:web:8e1bbd92cb89d992b20c39',
  measurementId: 'G-F0SR483TTY'
});

const {store, persistor} = createStore();

function render() {
  // NOTE: Workaround to fix https://sentry.io/organizations/slut-machine/issues/1883249466/?project=5414284
  // See also: https://github.com/facebook/react/issues/11538

  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;

    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn('Cannot remove a child from a different parent', child, this);
        }

        return child;
      }

      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn('Cannot insert before a reference node from a different parent', referenceNode, this);
        }

        return newNode;
      }

      return originalInsertBefore.apply(this, arguments);
    };
  }

  ReactDOM.render((
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App/>
      </PersistGate>
    </Provider>
  ), document.getElementById('app'));
}

const MIN_LOADING_TIME = 1000;

function waitForRehydration() {
  return new Promise(resolve => {
    if (store.getState()._persist.rehydrated) {
      resolve();

      return;
    }

    let unsubscribe = null;

    unsubscribe = store.subscribe(() => {
      if (store.getState()._persist.rehydrated) {
        unsubscribe();

        resolve();
      }
    });
  });
}

function waitForUserHasFetched() {
  return new Promise(resolve => {
    const {session, user} = store.getState();

    if (!session.isLoggedIn || user.hasFetched) {
      resolve();

      return;
    }

    let unsubscribe = null;

    unsubscribe = store.subscribe(() => {
      if (store.getState().user.hasFetched) {
        unsubscribe();

        resolve();
      }
    });
  });
}

waitForRehydration().then(() => waitForUserHasFetched()).then(() => {
  const loadingTime = Date.now() - window.loadingStartTimestamp;

  if (loadingTime > MIN_LOADING_TIME) {
    render();
  } else {
    setTimeout(render, MIN_LOADING_TIME - loadingTime);
  }
});
