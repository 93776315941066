// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mi_AlUeB{height:42px}.mi_AlUeB,.MS3dga5K{-js-display:flex;display:flex}.MS3dga5K{align-items:center;height:100%;padding:0 36px;border-bottom:2px solid transparent;font-size:20px;font-weight:700;cursor:pointer}.MS3dga5K.HqVhZfr5{border-bottom-color:#d50000;cursor:default}.MS3dga5K:not(.HqVhZfr5){color:#a9a9a9}", "",{"version":3,"sources":["webpack://src/components/common/tab-bar/styles.less"],"names":[],"mappings":"AAAA,UAEE,WACF,CACA,oBAHE,gBAAa,CAAb,YAYF,CATA,UAEE,kBAAmB,CACnB,WAAY,CACZ,cAAe,CACf,mCAAoC,CACpC,cAAe,CACf,eAAgB,CAChB,cACF,CACA,mBACE,2BAA4B,CAC5B,cACF,CACA,yBACE,aACF","sourcesContent":[".component {\n  display: flex;\n  height: 42px;\n}\n.item {\n  display: flex;\n  align-items: center;\n  height: 100%;\n  padding: 0 36px;\n  border-bottom: 2px solid transparent;\n  font-size: 20px;\n  font-weight: 700;\n  cursor: pointer;\n}\n.item.item-active {\n  border-bottom-color: #d50000;\n  cursor: default;\n}\n.item:not(.item-active) {\n  color: #a9a9a9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "mi_AlUeB",
	"item": "MS3dga5K",
	"item-active": "HqVhZfr5"
};
export default ___CSS_LOADER_EXPORT___;
