import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GuideItem} from '../../../lib/constants';
import {createTaskId, GameStage} from '../../../lib/game';

import Game from '../../../redux/ducks/game';
import GuideDuck from '../../../redux/ducks/guide';
import Settings from '../../../redux/ducks/settings';

import Button from '../../common/button';
import ClickableText from '../../common/clickable-text';
import Guide from '../../common/guide';
import Page from '../../common/page';

import Rating from './rating';
import Slots from './slots';

import styles from './styles.less';

const GUIDE_TEXT = 'Если вы вдруг ещё не знали: здесь находится меню.\n\n' +
  'Можно настроить игру под свои предпочтения и купить новые пакеты заданий.';

const GamePage = () => {
  const dispatch = useDispatch();

  const {stage, task} = useSelector(state => state.game);
  const {isLoggedIn} = useSelector(state => state.session);
  const {completedItems} = useSelector(state => state.guide);

  const openMenuRef = useRef(null);

  const [blacklistedTask, setBlacklistedTask] = useState(null);
  const [isMenuButtonGuideVisible, setMenuButtonGuideVisible] = useState(false);

  const handleClick = () => {
    switch (stage) {
    case GameStage.Initial:
      dispatch(Game.actions.startGame());

      break;
    case GameStage.Performing:
      dispatch(Game.actions.finishGame());

      if (!completedItems.includes(GuideItem.MenuButton)) {
        setMenuButtonGuideVisible(true);

        setTimeout(() => openMenuRef.current(), 1);
      }

      break;

    default:
      break;
    }
  };

  const addToBlacklist = () => {
    setBlacklistedTask(task);

    dispatch(Settings.actions.addRestrictedTaskId(createTaskId(task)));

    dispatch(Game.actions.finishGame());
  };

  const removeFromBlacklist = () => {
    dispatch(Game.actions.setTask(blacklistedTask));

    setBlacklistedTask(null);
  };

  const closeMenuButtonGuide = () => {
    setMenuButtonGuideVisible(false);

    dispatch(GuideDuck.actions.setGuideItemCompleted(GuideItem.MenuButton));
  };

  return (
    <Page contentUserClassName={styles['page-content']} openMenuRef={openMenuRef}>
      <div className={styles.content}>
        <Slots/>
        <div className={styles['game-controls']}>
          <Button
            isDisabled={stage === GameStage.Spinning}
            kind={Button.KIND.PRIMARY}
            text={(stage === GameStage.Performing) ? 'Выполнено' : 'Играть'}
            userClassName={styles['game-button']}
            onClick={handleClick}
          />
          <div className={styles.hint}>
            {(stage === GameStage.Initial) && 'Для запуска нажмите «Играть»'}
            {(stage === GameStage.Spinning) && 'Подождите…'}
            {(stage === GameStage.Performing) && 'Задание:'}
          </div>
          {
            (stage === GameStage.Initial) && blacklistedTask && (
              <div className={styles['blacklist-cancel-container']}>
                Добавлено в чёрный спосок. <ClickableText text="Отменить" onClick={removeFromBlacklist}/>
              </div>
            )
          }
          {
            (stage === GameStage.Performing) && (
              <>
                <div className={styles['task-text']}>
                  {
                    task ?
                      task.text :
                      'Что-то пошло не так, и мы не смогли подобрать вам задание :( Пожалуйста, попробуйте еще раз!'
                  }
                </div>
                {
                  task && (
                    <ClickableText
                      text="Больше не показывать"
                      userClassName={styles['blacklist-text']}
                      onClick={addToBlacklist}
                    />
                  )
                }
                {
                  isLoggedIn && task && (
                    <div className={styles['rating-wrapper']}>
                      <Rating task={task}/>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
        <Guide
          hollow={{
            extent: 360,
            top: -90,
            right: -40
          }}
          isVisible={isMenuButtonGuideVisible}
          text={GUIDE_TEXT}
          onCloseRequested={closeMenuButtonGuide}
        />
      </div>
    </Page>
  );
};

export default GamePage;
