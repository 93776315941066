// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3a6AFlz9{position:relative;margin-bottom:8px;font-size:12px;line-height:15px;color:#000}._3a6AFlz9.EgO3aNff{font-size:12px;color:#d50000}", "",{"version":3,"sources":["webpack://src/components/common/form/field-label/styles.less"],"names":[],"mappings":"AAAA,WACE,iBAAkB,CAClB,iBAAkB,CAClB,cAAe,CACf,gBAAiB,CACjB,UACF,CACA,oBACE,cAAe,CACf,aACF","sourcesContent":[".component {\n  position: relative;\n  margin-bottom: 8px;\n  font-size: 12px;\n  line-height: 15px;\n  color: #000;\n}\n.component.component-error {\n  font-size: 12px;\n  color: #d50000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_3a6AFlz9",
	"component-error": "EgO3aNff"
};
export default ___CSS_LOADER_EXPORT___;
