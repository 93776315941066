import classNames from 'classnames';
import React, {useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {SYMBOL_IMG_MAP} from '../../../lib/constants';
import {purchase} from '../../../lib/purchase';

import Button from '../../common/button';
import Image from '../../common/image';
import Page from '../../common/page';
import {AuthMode} from '../../common/page/auth-modal-content';

import styles from './styles.less';

const {symbols} = require('../../../assets/data/symbols.json');

const PacksPage = () => {
  const {items: userProducts} = useSelector(state => state.userProducts);

  const authCallbackRef = useRef(null);

  const [activeSymbol, setActiveSymbol] = useState(null);

  const availablePremiumSymbols = userProducts
    .filter(({type}) => type === 'symbol')
    .map(({name}) => name);

  const handlePackClick = symbol => () => {
    setActiveSymbol((symbol === activeSymbol) ? null : symbol);
  };

  const handleUnlockButtonClick = name => () => {
    purchase({
      name,
      type: 'symbol'
    }, () => {
      if (authCallbackRef.current) {
        authCallbackRef.current(AuthMode.SignUp);
      }
    });
  };

  return (
    <Page authCallbackRef={authCallbackRef} title="Пакеты заданий">
      <div className={styles.content}>
        {
          symbols
            .sort((s1, s2) => Number(s2.isPremium) - Number(s1.isPremium))
            .map(({isPremium, name, pack}) => {
              const packClassNames = classNames({
                [styles.pack]: true,
                [styles['pack-active']]: name === activeSymbol
              });

              const packDescriptionClassNames = classNames({
                [styles['pack-description']]: true,
                [styles['pack-description-active']]: name === activeSymbol
              });

              const packButtonWrapperClassNames = classNames({
                [styles['pack-button-wrapper']]: true,
                [styles['pack-button-wrapper-active']]: name === activeSymbol
              });

              return (
                <div key={name} className={styles['pack-wrapper']}>
                  <div className={packClassNames} onClick={handlePackClick(name)}>
                    <div className={styles['pack-row']}>
                      <div>
                        <div className={styles['pack-title']}>{pack.title}</div>
                        <div className={packDescriptionClassNames}>{pack.description}</div>
                      </div>
                      <div className={styles['pack-image-wrapper']}>
                        <Image data={SYMBOL_IMG_MAP[name]} extent={64}/>
                      </div>
                    </div>
                    <div className={packButtonWrapperClassNames}>
                      {
                        isPremium && !availablePremiumSymbols.includes(name) ? (
                          <Button
                            kind={Button.KIND.PRIMARY}
                            text="Разблокировать"
                            onClick={handleUnlockButtonClick(name)}
                          />
                        ) : (
                          <div>Доступно</div>
                        )
                      }
                    </div>
                  </div>
                </div>
              );
            })
        }
      </div>
    </Page>
  );
};

export default PacksPage;
