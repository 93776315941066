import classNames from 'classnames';
import StarIcon from 'mdi-material-ui/Star';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

import useHover from '../../../../../hooks/use-hover';

import styles from './styles.less';

const Star = ({isActive, isDisabled, onClick, onHover}) => {
  const componentRef = useRef(null);

  const isHovered = useHover(componentRef);

  useEffect(() => {
    if (!onHover || !isHovered) {
      return;
    }

    onHover();
  }, [onHover, isHovered]);

  const componentClassNames = classNames({
    [styles.component]: true,
    [styles['component-active']]: isActive || isHovered,
    [styles['component-disabled']]: isDisabled
  });

  return (
    <div ref={componentRef} className={componentClassNames} onClick={isDisabled ? null : onClick}>
      <StarIcon className={styles.icon}/>
    </div>
  );
};

Star.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func
};

Star.defaultProps = {
  isActive: false,
  isDisabled: false,
  onHover: null
};

export default Star;
