// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../assets/img/icons/btn-google.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3oeuPLpE{min-width:120px!important;padding:0 0 0 10px!important;box-sizing:border-box;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -3px no-repeat;background-color:#fff!important;background-size:40px 40px}._3ypNd4vZ{width:320px;padding:20px;box-sizing:border-box}._1e6ndkd-{margin-top:30px;-js-display:flex;display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://src/components/common/page/auth-modal-content/styles.less"],"names":[],"mappings":"AAAA,WACE,yBAA2B,CAC3B,4BAA8B,CAC9B,qBAAsB,CACtB,iEAA6E,CAC7E,+BAAiC,CACjC,yBACF,CACA,WACE,WAAY,CACZ,YAAa,CACb,qBACF,CACA,WACE,eAAgB,CAChB,gBAAa,CAAb,YAAa,CACb,6BACF","sourcesContent":[".btn-google {\n  min-width: 120px !important;\n  padding: 0 0 0 10px !important;\n  box-sizing: border-box;\n  background: url('../../../../assets/img/icons/btn-google.svg') -3px no-repeat;\n  background-color: #fff !important;\n  background-size: 40px 40px;\n}\n.component {\n  width: 320px;\n  padding: 20px;\n  box-sizing: border-box;\n}\n.footer {\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-google": "_3oeuPLpE",
	"component": "_3ypNd4vZ",
	"footer": "_1e6ndkd-"
};
export default ___CSS_LOADER_EXPORT___;
