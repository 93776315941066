import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useForm} from 'react-form';

import Button from '../../common/button';
import ClickableText from '../../common/clickable-text';

import FormField from './form-field';

import styles from './styles.less';

const FormAction = ({action, isDisabled}) => {
  const {isPrimary, type, buttonWidth, text, href, userClassName, userStyle, onClick} = action;

  const isText = type === Form.ACTION_TYPE.TEXT;

  if (isPrimary || !isText) {
    const buttonKind = type || (isPrimary ? Button.KIND.PRIMARY : Button.KIND.SECONDARY);
    const buttonType = isPrimary ? Button.TYPE.SUBMIT : Button.TYPE.BUTTON;

    return (
      <Button
        href={isPrimary ? null : href}
        isDisabled={(isPrimary && isDisabled) || action.isDisabled}
        kind={buttonKind}
        text={text}
        type={buttonType}
        userClassName={userClassName}
        userStyle={userStyle}
        width={buttonWidth || Button.WIDTH.NORMAL}
        onClick={isPrimary ? null : onClick}
      />
    );
  }

  if (isText) {
    return (
      <div className={styles['action-text-wrapper']}>
        <ClickableText href={href} text={text} onClick={onClick}/>
      </div>
    );
  }

  return (
    <Button kind={type} text={text} onClick={onClick}/>
  );
};

FormAction.propTypes = {
  action: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool
};

FormAction.defaultProps = {
  isDisabled: false
};

const FormColumn = ({fields, isDisabled}) => {
  return (
    <div className={styles.column}>
      {
        /* eslint-disable react/no-array-index-key */
        fields.map((field, index) => (
          <FormFieldWrapper key={index} field={field} isDisabled={isDisabled}/>
        ))
        /* eslint-enable react/no-array-index-key */
      }
    </div>
  );
};

FormColumn.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool
};

FormColumn.defaultProps = {
  isDisabled: false
};

const FormFieldWrapper = ({field, isDisabled}) => {
  if (field.type === 'row') {
    return (
      <FormRow columns={field.columns} isDisabled={isDisabled}/>
    );
  }

  if (field.type === 'spacer') {
    return (
      <div style={{height: `${field.size}px`}}/>
    );
  }

  return (
    <div className={styles['field-wrapper']}>
      <FormField {...field} isDisabled={isDisabled || field.disabled}/>
    </div>
  );
};

FormFieldWrapper.propTypes = {
  field: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool
};

FormFieldWrapper.defaultProps = {
  isDisabled: false
};

const FormRow = ({columns, isDisabled}) => {
  return (
    <div className={styles.row}>
      {
        /* eslint-disable react/no-array-index-key */
        columns.map(({fields}, index) => (
          <FormColumn key={index} fields={fields} isDisabled={isDisabled}/>
        ))
        /* eslint-enable react/no-array-index-key */
      }
    </div>
  );
};

FormRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool
};

FormRow.defaultProps = {
  isDisabled: false
};

const Form = props => {
  const {
    formApiRef,
    submitCallbackRef,
    actionAlignment,
    actions,
    children,
    defaultValues,
    error,
    fields,
    isDisabled,
    title,
    validate,
    onSubmit,
    onSubmitFailure
  } = props;

  const alignCenter = actionAlignment === Form.ACTION_ALIGNMENT.CENTER;

  const actionsClassNames = classNames({
    [styles.actions]: true,
    [styles['space-between']]: alignCenter && (actions.length > 1),
    [styles.center]: alignCenter && (actions.length === 1),
    [styles.start]: actionAlignment === Form.ACTION_ALIGNMENT.START,
    [styles.end]: actionAlignment === Form.ACTION_ALIGNMENT.END
  });

  const setSubmitCallbackRef = el => {
    if (!submitCallbackRef) {
      return;
    }

    const callback = () => {
      el.click();
    };

    if (typeof submitCallbackRef === 'function') {
      submitCallbackRef(callback);
    } else {
      submitCallbackRef.current = callback;
    }
  };

  const options = {
    validate: (values, instance) => {
      // Required to reset global form error

      const result = validate ? validate(values) : {};

      Object.keys(values).forEach(key => {
        instance.setFieldMeta(key, {error: result[key]});
      });

      return result._error;
    },
    onSubmit: async (values, instance) => {
      try {
        await onSubmit(values);
      } catch (err) {
        if (!onSubmitFailure) {
          return;
        }

        onSubmitFailure(err, (key, value) => {
          if (key === '_error') {
            instance.setMeta({error: value});
          } else {
            instance.setFieldMeta(key, {error: value});
          }
        });
      }
    }
  };

  const formDefaultValues = useMemo(() => defaultValues, [JSON.stringify(defaultValues)]);

  if (formDefaultValues) {
    options.defaultValues = formDefaultValues;
  }

  const formInstance = useForm(options);

  const {
    Form: ReactForm,
    meta: {error: formError}
  } = formInstance;

  if (typeof formApiRef === 'function') {
    formApiRef(formInstance);
  } else if (formApiRef && (typeof formApiRef === 'object')) {
    formApiRef.current = formInstance;
  }

  return (
    <ReactForm className={styles.form}>
      {
        title && (
          <h5 className={styles.title}>{title}</h5>
        )
      }
      {
        children && (
          <div className={styles['children-container']}>
            {children}
          </div>
        )
      }
      {
        fields && (fields.length > 0) && (
          <fieldset className={styles.fields}>
            {
              /* eslint-disable react/no-array-index-key */
              fields.map((field, index) => (
                <FormFieldWrapper key={index} field={field} isDisabled={isDisabled}/>
              ))
              /* eslint-enable react/no-array-index-key */
            }
          </fieldset>
        )
      }
      {
        (actions.length > 0) && (
          <div className={actionsClassNames}>
            {
              actions.map(action => (
                <FormAction
                  key={`action-${action.isPrimary}-${action.type}-${action.text}`}
                  action={action}
                  isDisabled={isDisabled}
                />
              ))
            }
          </div>
        )
      }
      {
        Boolean(error || formError) && (
          <div className={styles.error}>
            {error || formError}
          </div>
        )
      }
      <button ref={setSubmitCallbackRef} type="submit" style={{display: 'none'}}/>
    </ReactForm>
  );
};

Form.ACTION_TYPE = {
  ...Button.KIND,
  TEXT: 'text'
};

Form.ACTION_ALIGNMENT = {
  CENTER: 'center',
  START: 'start',
  END: 'end'
};

Form.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.shape({
    isPrimary: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(Form.ACTION_TYPE)),
    buttonWidth: PropTypes.oneOf(Object.values(Button.WIDTH)),
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    userClassName: PropTypes.string,
    userStyle: PropTypes.object,
    onClick: PropTypes.func
  })),
  actionAlignment: PropTypes.oneOf(Object.values(Form.ACTION_ALIGNMENT)),
  validate: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFailure: PropTypes.func,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  submitCallbackRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  formApiRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

Form.defaultProps = {
  children: null,
  title: null,
  fields: null,
  defaultValues: null,
  actions: [],
  actionAlignment: Form.ACTION_ALIGNMENT.CENTER,
  validate: null,
  onSubmit: null,
  onSubmitFailure: null,
  isDisabled: false,
  error: null,
  submitCallbackRef: null,
  formApiRef: null
};

export default Form;
