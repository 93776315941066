// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1s8WTYFC{justify-content:space-between;flex-shrink:0;height:40px;width:100%;padding:0 16px;box-sizing:border-box;background-color:#d50000;color:#fafafa}._1s8WTYFC,._26k0w-FY{-js-display:flex;display:flex}._26k0w-FY{align-items:center;height:100%}._3KkkqxVi{height:32px;width:32px}._1FUFPjI6{height:100%!important;width:100%!important;cursor:pointer}.xBHxlATQ{-js-display:flex;display:flex;align-items:center;height:100%}._3gQ2OB-K{font-size:20px}", "",{"version":3,"sources":["webpack://src/components/common/page/header/styles.less"],"names":[],"mappings":"AAAA,WAEE,6BAA8B,CAC9B,aAAc,CACd,WAAY,CACZ,UAAW,CACX,cAAe,CACf,qBAAsB,CACtB,wBAAyB,CACzB,aACF,CACA,sBAVE,gBAAa,CAAb,YAcF,CAJA,WAEE,kBAAmB,CACnB,WACF,CACA,WACE,WAAY,CACZ,UACF,CACA,WACE,qBAAuB,CACvB,oBAAsB,CACtB,cACF,CACA,UACE,gBAAa,CAAb,YAAa,CACb,kBAAmB,CACnB,WACF,CACA,WACE,cACF","sourcesContent":[".component {\n  display: flex;\n  justify-content: space-between;\n  flex-shrink: 0;\n  height: 40px;\n  width: 100%;\n  padding: 0 16px;\n  box-sizing: border-box;\n  background-color: #d50000;\n  color: #fafafa;\n}\n.left-container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n.menu {\n  height: 32px;\n  width: 32px;\n}\n.menu-button {\n  height: 100% !important;\n  width: 100% !important;\n  cursor: pointer;\n}\n.right-container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n.title {\n  font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": "_1s8WTYFC",
	"left-container": "_26k0w-FY",
	"menu": "_3KkkqxVi",
	"menu-button": "_1FUFPjI6",
	"right-container": "xBHxlATQ",
	"title": "_3gQ2OB-K"
};
export default ___CSS_LOADER_EXPORT___;
