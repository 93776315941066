import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

const subnamespace = defineAction('settings');

const ADD_RESTRICTED_FETISH = defineAction('ADD_RESTRICTED_FETISH', subnamespace);
const ADD_RESTRICTED_TASK_ID = defineAction('ADD_RESTRICTED_TASK_ID', subnamespace);
const REMOVE_RESTRICTED_FETISH = defineAction('REMOVE_RESTRICTED_FETISH', subnamespace);
const REMOVE_RESTRICTED_TASK_ID = defineAction('REMOVE_RESTRICTED_TASK_ID', subnamespace);
const SET_SELECTED_FETISHES_ONLY = defineAction('SET_SELECTED_FETISHES_ONLY', subnamespace);

const addRestrictedFetish = createAction(ADD_RESTRICTED_FETISH.ACTION);
const addRestrictedTaskId = createAction(ADD_RESTRICTED_TASK_ID.ACTION);
const removeRestrictedFetish = createAction(REMOVE_RESTRICTED_FETISH.ACTION);
const removeRestrictedTaskId = createAction(REMOVE_RESTRICTED_TASK_ID.ACTION);
const setSelectedFetishesOnly = createAction(SET_SELECTED_FETISHES_ONLY.ACTION);

const INITIAL_STATE = {
  restrictedFetishes: {
    female: [],
    male: []
  },
  restrictedTaskIds: [],
  selectedFetishesOnly: {
    female: false,
    male: false
  }
};

function handleAddRestrictedFetish(state, {payload}) {
  const {gender, name} = payload;

  const newItems = [...state.restrictedFetishes[gender]];

  if (newItems.includes(name)) {
    return state;
  }

  newItems.push(name);

  return {
    ...state,
    restrictedFetishes: {
      ...state.restrictedFetishes,
      [gender]: newItems
    }
  };
}

function handleAddRestrictedTaskId(state, {payload}) {
  const newItems = [...state.restrictedTaskIds];

  if (newItems.includes(payload)) {
    return state;
  }

  newItems.push(payload);

  return {
    ...state,
    restrictedTaskIds: newItems
  };
}

function handleRemoveRestrictedFetish(state, {payload}) {
  const {gender, name} = payload;

  const newItems = [...state.restrictedFetishes[gender]];

  const index = newItems.indexOf(name);

  if (index < 0) {
    return state;
  }

  newItems.splice(index, 1);

  return {
    ...state,
    restrictedFetishes: {
      ...state.restrictedFetishes,
      [gender]: newItems
    }
  };
}

function handleRemoveRestrictedTaskId(state, {payload}) {
  const newItems = [...state.restrictedTaskIds];

  const index = newItems.indexOf(payload);

  if (index < 0) {
    return state;
  }

  newItems.splice(index, 1);

  return {
    ...state,
    restrictedTaskIds: newItems
  };
}

function handleSetSelectedFetishesOnly(state, {payload}) {
  const {gender, enabled} = payload;

  return {
    ...state,
    selectedFetishesOnly: {
      ...state.selectedFetishesOnly,
      [gender]: Boolean(enabled)
    }
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    ADD_RESTRICTED_FETISH,
    ADD_RESTRICTED_TASK_ID,
    REMOVE_RESTRICTED_FETISH,
    REMOVE_RESTRICTED_TASK_ID,
    SET_SELECTED_FETISHES_ONLY
  },
  actions: {
    addRestrictedFetish,
    addRestrictedTaskId,
    removeRestrictedFetish,
    removeRestrictedTaskId,
    setSelectedFetishesOnly
  },
  reducer: createReducer(INITIAL_STATE, {
    [ADD_RESTRICTED_FETISH.ACTION]: handleAddRestrictedFetish,
    [ADD_RESTRICTED_TASK_ID.ACTION]: handleAddRestrictedTaskId,
    [REMOVE_RESTRICTED_FETISH.ACTION]: handleRemoveRestrictedFetish,
    [REMOVE_RESTRICTED_TASK_ID.ACTION]: handleRemoveRestrictedTaskId,
    [SET_SELECTED_FETISHES_ONLY.ACTION]: handleSetSelectedFetishesOnly
  })
};

export default DUCK;
