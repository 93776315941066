// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ETVzN2v{height:38px;width:100%;padding:9px 15px 8px;box-sizing:border-box;border:1px solid #000;border-radius:3px;background-color:#fafafa;font-size:15px;font-family:Balsamiq Sans,sans-serif;color:#000}._1ETVzN2v._3Jj4XfNO{font-size:14px}._1ETVzN2v._2XLvGGPd{border-color:#d50000}._1ETVzN2v:focus{outline:0}._1ETVzN2v::-moz-placeholder{opacity:.25;font-size:16px;font-family:Balsamiq Sans,sans-serif;line-height:1.36;color:#000}._1ETVzN2v::placeholder{opacity:.25;font-size:16px;font-family:Balsamiq Sans,sans-serif;line-height:1.36;color:#000}._25HjhL8q{position:relative;display:inline-block;width:100%}", "",{"version":3,"sources":["webpack://src/components/common/form/form-field/input/styles.less"],"names":[],"mappings":"AAAA,WACE,WAAY,CACZ,UAAW,CACX,oBAA0B,CAC1B,qBAAsB,CACtB,qBAAsB,CACtB,iBAAkB,CAClB,wBAAyB,CACzB,cAAe,CACf,oCAAsC,CACtC,UACF,CACA,qBACE,cACF,CACA,qBACE,oBACF,CACA,iBACE,SACF,CACA,6BACE,WAAa,CACb,cAAe,CACf,oCAAsC,CACtC,gBAAiB,CACjB,UACF,CANA,wBACE,WAAa,CACb,cAAe,CACf,oCAAsC,CACtC,gBAAiB,CACjB,UACF,CACA,WACE,iBAAkB,CAClB,oBAAqB,CACrB,UACF","sourcesContent":[".input {\n  height: 38px;\n  width: 100%;\n  padding: 9px 15px 8px 15px;\n  box-sizing: border-box;\n  border: 1px solid #000;\n  border-radius: 3px;\n  background-color: #fafafa;\n  font-size: 15px;\n  font-family: Balsamiq Sans, sans-serif;\n  color: #000;\n}\n.input.disabled {\n  font-size: 14px;\n}\n.input.with-error {\n  border-color: #d50000;\n}\n.input:focus {\n  outline: 0;\n}\n.input::placeholder {\n  opacity: 0.25;\n  font-size: 16px;\n  font-family: Balsamiq Sans, sans-serif;\n  line-height: 1.36;\n  color: #000;\n}\n.input-form-field {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "_1ETVzN2v",
	"disabled": "_3Jj4XfNO",
	"with-error": "_2XLvGGPd",
	"input-form-field": "_25HjhL8q"
};
export default ___CSS_LOADER_EXPORT___;
