import MenuIcon from 'mdi-material-ui/Menu';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import history from '../../../../lib/history';

import Auth from '../../../../redux/ducks/auth';

import useMenu from '../../../../hooks/use-menu';

import Menu, {MenuSeparator} from '../../../common/menu';

import {AuthMode} from '../auth-modal-content';

import styles from './styles.less';

import {useLocation} from 'react-router-dom';

const NAVIGATION_ITEMS = [{
  path: '/game',
  title: 'Игра'
}, {
  path: '/settings',
  title: 'Настройки'
}, {
  path: '/packs',
  title: 'Пакеты заданий'
}, {
  path: '/blacklist',
  title: 'Чёрный список'
}, {
  path: '/about',
  title: 'О проекте'
}];

const Header = ({title, openMenuRef, onAuthRequested}) => {
  const location = useLocation();

  const dispatch = useDispatch();

  const [menuProps, {openMenu, toggleMenu}] = useMenu();

  useEffect(() => {
    if (openMenuRef) {
      openMenuRef.current = openMenu;
    }
  }, [openMenuRef, openMenu]);

  const {isLoggedIn} = useSelector(state => state.session);
  const {item: user} = useSelector(state => state.user);

  const menuItems = [];

  if (isLoggedIn && user && user.email) {
    menuItems.push({
      text: user.email,
      onClick: () => {
        // eslint-disable-next-line no-alert
        alert(user.email);
      }
    }, MenuSeparator);
  }

  const navigationItems = NAVIGATION_ITEMS
    .filter(({path}) => path !== location.pathname)
    .map(({path, title}) => ({
      text: title,
      onClick: () => history.push(path)
    }));

  menuItems.push(...navigationItems, MenuSeparator, {
    text: isLoggedIn ? 'Выйти' : 'Вход / Регистрация',
    onClick: () => {
      if (isLoggedIn) {
        dispatch(Auth.actions.logOut());
      } else {
        onAuthRequested(AuthMode.LogIn);
      }
    }
  });

  return (
    <header className={styles.component}>
      <div className={styles['left-container']}>
        {
          Boolean(title) && (
            <div className={styles.title}>{title}</div>
          )
        }
      </div>
      <div className={styles['right-container']}>
        <Menu {...menuProps} minMenuWidth={180} userClassName={styles.menu} items={menuItems}>
          <MenuIcon className={styles['menu-button']} onClick={toggleMenu}/>
        </Menu>
      </div>
    </header>
  );
};

Header.propTypes = {
  openMenuRef: PropTypes.object,
  title: PropTypes.string,
  onAuthRequested: PropTypes.func.isRequired
};

Header.defaultProps = {
  openMenuRef: null,
  title: null
};

export default Header;
