import React from 'react';

import Page from '../../common/page';

const NotFoundPage = () => {
  return (
    <Page isSfw title="Страница не найдена">
      Страница не найдена :(
    </Page>
  );
};

export default NotFoundPage;
