import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import Button from '../button';

import styles from './styles.less';

const Guide = ({hollow, isVisible, text, onCloseRequested}) => {
  const overlayStyle = isVisible ? {} : {display: 'none'};
  const hollowStyle = {
    top: hollow.top ? `${hollow.top}px` : undefined,
    left: hollow.left ? `${hollow.left}px` : undefined,
    right: hollow.right ? `${hollow.right}px` : undefined,
    bottom: hollow.bottom ? `${hollow.bottom}px` : undefined,
    height: `${hollow.extent}px`,
    width: `${hollow.extent}px`
  };

  useEffect(() => {
    const handleClickOutside = () => {
      if (isVisible && onCloseRequested) {
        onCloseRequested();
      }
    };

    window.document.addEventListener('click', handleClickOutside);

    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, [isVisible, onCloseRequested]);

  return (
    <div className={styles.overlay} style={overlayStyle}>
      <div className={styles.hollow} style={hollowStyle}/>
      <div className={styles.content}>
        <div className={styles.text}>{text}</div>
        <div className={styles['action-wrapper']}>
          <Button kind={Button.KIND.PRIMARY} text="Понятно!" onClick={onCloseRequested}/>
        </div>
      </div>
    </div>
  );
};

Guide.propTypes = {
  hollow: PropTypes.shape({
    extent: PropTypes.number.isRequired,
    left: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number
  }).isRequired,
  isVisible: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onCloseRequested: PropTypes.func
};

Guide.defaultProps = {
  isVisible: false,
  onCloseRequested: null
};

export default Guide;
