import {call, put, select, takeLatest} from 'redux-saga/effects';

import {GameStage, MAX_SPINNING_TIME, MIN_SPINNING_TIME, chooseTask, createTaskId} from '../../lib/game';
import {delay, getRandomInteger} from '../../lib/helper';

import Game from '../ducks/game';
import LastTaskIds from '../ducks/last-task-ids';

function * handleStartGame() {
  yield call(delay, getRandomInteger(MIN_SPINNING_TIME, MAX_SPINNING_TIME));

  const {stage} = yield select(state => state.game);

  if (stage !== GameStage.Spinning) {
    return;
  }

  const {items: availableTasks} = yield select(state => state.tasks);
  const settings = yield select(state => state.settings);
  const {ids: lastTaskIds} = yield select(state => state.lastTaskIds);
  const {items: userProducts} = yield select(state => state.userProducts);

  const availablePremiumSymbols = userProducts
    .filter(({type}) => type === 'symbol')
    .map(({name}) => name);

  const task = chooseTask({availablePremiumSymbols, availableTasks, lastTaskIds, settings});

  yield put(Game.actions.setTask(task));

  if (task) {
    yield put(LastTaskIds.actions.addLastTaskId(createTaskId(task)));
  }
}

export default function * session() {
  yield takeLatest(Game.actionTypes.START_GAME.ACTION, handleStartGame);
}
