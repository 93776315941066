import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import styles from './styles.less';

const ClickableText = ({domRef, href, isDisabled, target, text, title, userClassName, userStyle, onClick}) => {
  const clickableTextClassNames = classNames({
    [userClassName]: Boolean(userClassName),
    [styles['clickable-text']]: true,
    [styles.disabled]: isDisabled
  });

  if (isDisabled) {
    return (
      <span ref={domRef} className={clickableTextClassNames} style={userStyle} title={title}>
        {text}
      </span>
    );
  }

  if (!href) {
    return (
      <span ref={domRef} className={clickableTextClassNames} style={userStyle} title={title} onClick={onClick}>
        {text}
      </span>
    );
  }

  if (/^(https?:)?\/\//.test(href) || target) {
    const linkProps = {
      href,
      title,
      ref: domRef,
      className: clickableTextClassNames,
      style: userStyle
    };

    if (target) {
      linkProps.target = target;
    }

    return (
      <a {...linkProps}>
        {text}
      </a>
    );
  }

  return (
    <Link innerRef={domRef} to={href} className={clickableTextClassNames} style={userStyle} title={title}>
      {text}
    </Link>
  );
};

ClickableText.propTypes = {
  domRef: PropTypes.func,
  href: PropTypes.string,
  isDisabled: PropTypes.bool,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onClick: PropTypes.func
};

ClickableText.defaultProps = {
  domRef: null,
  href: null,
  isDisabled: false,
  target: null,
  title: '',
  userClassName: null,
  userStyle: null,
  onClick: null
};

export default ClickableText;
