import {createReducer} from '@ololoepepe/redux-tools';

import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

const subnamespace = defineAction('maturity');

const SET_MATURE = defineAction('SET_MATURE', subnamespace);

const setMature = createAction(SET_MATURE.ACTION);

const INITIAL_STATE = {
  isMature: false
};

function handleSetMature(state, {payload}) {
  return {
    ...state,
    isMature: Boolean(payload)
  };
}

const DUCK = {
  subnamespace,
  actionTypes: {
    SET_MATURE
  },
  actions: {
    setMature
  },
  reducer: createReducer(INITIAL_STATE, {
    [SET_MATURE.ACTION]: handleSetMature
  })
};

export default DUCK;
