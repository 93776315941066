import {GENDERS} from './constants';
import {getRandomInteger, isLandscapeLayout} from './helper';

const {symbols} = require('../assets/data/symbols');

const PREMIUM_SYMBOLS = symbols.filter(({isPremium}) => isPremium).map(({name}) => name);

export const GameStage = {
  Initial: 'initial',
  Spinning: 'spinning',
  Performing: 'performing'
};

export const MIN_SPINNING_TIME = 2000;
export const MAX_SPINNING_TIME = 4000;

export const SLOT_COUNT = 3;

const HEADER_HEIGHT = 40;
const FOOTER_HEIGHT = 48;

const PAGE_PADDING = 10;

const SLOT_MACHINE_PADDING_HORIZONTAL = 10;

const SLOTS_PADDING_VERTICAL = 10;

const SLOT_CONTAINER_PADDING_HORIZONTAL = 5;

const SLOT_BORDER_SIZE = 1;
const SLOT_PADDING = 4;

const VERTICAL_SPACE_CORRECTION =
  HEADER_HEIGHT + FOOTER_HEIGHT + (2 * PAGE_PADDING) + (2 * SLOTS_PADDING_VERTICAL) +
  (SLOT_COUNT * 2 * SLOT_BORDER_SIZE) + (SLOT_COUNT * 2 * SLOT_PADDING);
const HORIZONTAL_SPACE_CORRECTION = (2 * PAGE_PADDING) + (2 * SLOT_MACHINE_PADDING_HORIZONTAL) +
  (((SLOT_COUNT * 2) - 2) * SLOT_CONTAINER_PADDING_HORIZONTAL) +
  (SLOT_COUNT * 2 * SLOT_BORDER_SIZE) + (SLOT_COUNT * 2 * SLOT_PADDING);

const MAX_EXTENT = 237;

const SLOT_ASPECT_RATIO = 3 / 5;

const LANDSCAPE_WIDTH_PERCENTAGE = 0.5;
const PORTRAIT_HEIGHT_PERCENTAGE = 0.6;

const DIMENSION_CACHE = new Map();

export function getRandomSymbols(availablePremiumSymbols) {
  const list = symbols.filter(({isPremium, name}) => !isPremium || availablePremiumSymbols.includes(name));

  return Array(SLOT_COUNT).fill(null).map(() => list[getRandomInteger(0, list.length - 1)]);
}

export function getSlotDimensions(wd) {
  const hash = `${wd.width}x${wd.height}`;

  const cached = DIMENSION_CACHE.get(hash);

  if (cached) {
    return cached;
  }

  const space = {
    height: wd.height - VERTICAL_SPACE_CORRECTION,
    width: wd.width - HORIZONTAL_SPACE_CORRECTION
  };

  let width = MAX_EXTENT;
  let height = width / SLOT_ASPECT_RATIO;

  if (isLandscapeLayout(wd)) {
    if ((SLOT_COUNT * width) > (LANDSCAPE_WIDTH_PERCENTAGE * space.width)) {
      width = (LANDSCAPE_WIDTH_PERCENTAGE * space.width) / SLOT_COUNT;
      height = width / SLOT_ASPECT_RATIO;
    }

    if (height > space.height) {
      height = space.height;
      width = height * SLOT_ASPECT_RATIO;
    }
  } else {
    if (height > (PORTRAIT_HEIGHT_PERCENTAGE * space.height)) {
      height = (PORTRAIT_HEIGHT_PERCENTAGE * space.height);
      width = height * SLOT_ASPECT_RATIO;
    }

    if ((SLOT_COUNT * width) > space.width) {
      width = space.width / SLOT_COUNT;
      height = width / SLOT_ASPECT_RATIO;
    }
  }

  const result = {width, height};

  DIMENSION_CACHE.set(hash, result);

  return result;
}

const _shouldIncludeTask = ({restrictedFetishes, restrictedTaskIds, selectedFetishesOnly}) => ({fetishes, id}) => {
  if (restrictedTaskIds.includes(id)) {
    return false;
  }

  return GENDERS.every(gender => {
    return fetishes[gender].every(name => !restrictedFetishes[gender].includes(name)) &&
      (!selectedFetishesOnly[gender] || (fetishes[gender].length > 0));
  });
};

export function createTaskId({index, symbols}) {
  return `${symbols.join('-')}-${index}`;
}

export function parseTaskId(id) {
  const [s1, s2, s3, index] = id.split('-');

  return {
    index: Number(index),
    symbols: [s1, s2, s3]
  };
}

function _getAvailableTasksFlat({availablePremiumSymbols, availableTasks, settings}) {
  const list = [];

  const shouldIncludeTask = _shouldIncludeTask(settings);

  availableTasks.forEach(({symbols, tasks}) => {
    if (symbols.some(symbol => PREMIUM_SYMBOLS.includes(symbol) && !availablePremiumSymbols.includes(symbol))) {
      return;
    }

    tasks.forEach((task, index) => {
      const t = {
        ...task,
        index,
        symbols,
        id: createTaskId({index, symbols})
      };

      if (shouldIncludeTask(t)) {
        list.push(t);
      }
    });
  });

  return list;
}

function _getExcludedTaskSet({availableTasksFlat, lastTaskIds}) {
  const lastTaskIdSet = new Set(lastTaskIds);

  const hasUnseenTasks = availableTasksFlat.some(({id}) => !lastTaskIdSet.has(id));

  const lastTaskCount = Math.ceil(Math.sqrt(availableTasksFlat.length));

  return hasUnseenTasks ? lastTaskIdSet : new Set(lastTaskIds.slice(-lastTaskCount));
}

export function chooseTask({availablePremiumSymbols, availableTasks, lastTaskIds, settings}) {
  if (availableTasks.length < 1) {
    return null;
  }

  const availableTasksFlat = _getAvailableTasksFlat({availablePremiumSymbols, availableTasks, settings});
  const excludedTaskIdSet = _getExcludedTaskSet({availableTasksFlat, lastTaskIds});

  const list = availableTasksFlat.filter(({id}) => !excludedTaskIdSet.has(id));

  return list[getRandomInteger(0, list.length - 1)];
}
