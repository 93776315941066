// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1GTRHiB0{background-color:#fafafa;border-radius:3px;cursor:default}._2cVmtVP5{position:fixed;z-index:1000;top:0;left:0;-js-display:flex;display:flex;justify-content:center;align-items:center;height:100%;width:100%;background-color:rgba(0,0,0,.6)}._2cVmtVP5:not(._4BcdF7B-){cursor:pointer}", "",{"version":3,"sources":["webpack://src/components/common/modal/styles.less"],"names":[],"mappings":"AAAA,WACE,wBAAyB,CACzB,iBAAkB,CAClB,cACF,CACA,WACE,cAAe,CACf,YAAa,CACb,KAAM,CACN,MAAO,CACP,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,WAAY,CACZ,UAAW,CACX,+BACF,CACA,2BACE,cACF","sourcesContent":[".modal {\n  background-color: #fafafa;\n  border-radius: 3px;\n  cursor: default;\n}\n.overlay {\n  position: fixed;\n  z-index: 1000;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.6);\n}\n.overlay:not(.overlay-not-clickable) {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "_1GTRHiB0",
	"overlay": "_2cVmtVP5",
	"overlay-not-clickable": "_4BcdF7B-"
};
export default ___CSS_LOADER_EXPORT___;
