// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2dYVXsfc+._2dYVXsfc{margin-left:5px}.NDZWohEU{margin-top:15px;-js-display:flex;display:flex}", "",{"version":3,"sources":["webpack://src/components/pages/game/rating/styles.less"],"names":[],"mappings":"AAAA,sBACE,eACF,CACA,UACE,eAAgB,CAChB,gBAAa,CAAb,YACF","sourcesContent":[".star-wrapper + .star-wrapper {\n  margin-left: 5px;\n}\n.stars-container {\n  margin-top: 15px;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star-wrapper": "_2dYVXsfc",
	"stars-container": "NDZWohEU"
};
export default ___CSS_LOADER_EXPORT___;
