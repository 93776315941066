import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

const FieldLabel = ({isError, text}) => {
  const componentClassNames = classNames({
    [styles.component]: true,
    [styles['component-error']]: isError
  });

  return (
    <div className={componentClassNames}>
      {text}
    </div>
  );
};

FieldLabel.propTypes = {
  isError: PropTypes.bool,
  text: PropTypes.string
};

FieldLabel.defaultProps = {
  isError: false,
  text: ''
};

export default FieldLabel;
